const ProfessionCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }

  return (
    <div className='w-full flex justify-center'>
      <span>
        {info == 0
          ? "Doctor"
          : info == 1
            ? "Nurse"
            : info == 2
              ? "Technician"
              : "Other"}
      </span>
    </div>
  );
};

export default ProfessionCell;

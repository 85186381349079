import { useLocation } from "react-router-dom";

import { Disclosure, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

import logo from "../../images/logo-no-bird.png";

const navItems = [
  { name: "How it Works", href: "/howitworks" },
  { name: "About us", href: "/aboutus" },
  { name: "FAQ", href: "/faq" },
  { name: "Contact", href: "/contact" },
];

const loginItem = { name: "Log in", href: `/login` };

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const NavBar = () => {
  let location = useLocation();

  return (
    <motion.div initial={false} animate={false} exit={false}>
      <Disclosure
        as='nav'
        className='bg-zinc-900 h-20 fixed top-0 w-full clear-both'
      >
        {({ open }) => (
          <>
            <div className='mx-auto px-8 transition-all'>
              <div className='relative flex h-20 items-center justify-between'>
                {/* Mobile Menu Button */}
                <div className='absolute inset-y-0 left-0 flex items-center md:hidden'>
                  <Disclosure.Button className='relative inline-flex items-center justify-center transition-all rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                    <span className='absolute -inset-0.5' />
                    <span className='sr-only'>Open main menu</span>
                    {open ? (
                      <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                    ) : (
                      <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                    )}
                  </Disclosure.Button>
                </div>

                <div className='flex flex-1 items-center justify-center md:items-stretch md:justify-end md:ml-4'>
                  <div
                    className='md:absolute md:inset-y-0 md:left-0 flex flex-shrink-0 items-center'
                    href='/'
                  >
                    <a draggable='false' href={`/`}>
                      <img
                        className='h-8 w-auto hover:brightness-150 transition-all'
                        src={logo}
                        alt='Fly Away Students Logo'
                        draggable='false'
                      />
                    </a>
                  </div>
                  <div className='hidden md:ml-10 md:flex md:items-center md:text-lg'>
                    <div className='flex space-x-4'>
                      {navItems.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href === location.pathname
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-lg font-medium transition-all",
                          )}
                          aria-current={
                            item.href === location.pathname ? true : undefined
                          }
                        >
                          {item.name}
                        </a>
                      ))}
                      <a href={loginItem.href}>
                        <button
                          type='button'
                          className='text-white bg-red-700 hover:bg-red-800 focus:ring-2 focus:ring-red-900 font-medium rounded-lg text-md px-5 py-2.5 me-2 dark:bg-red-700 dark:hover:bg-red-800 focus:outline-none dark:focus:ring-red-900 transition-all'
                        >
                          {loginItem.name}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              <Disclosure.Panel className='bg-zinc-900 md:hidden'>
                <div className='space-y-1 px-2 pb-3 pt-2'>
                  {[...navItems, loginItem].map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as='a'
                      href={item.href}
                      className={classNames(
                        item.href === location.pathname
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium",
                      )}
                      aria-current={
                        item.href === location.pathname ? "page" : undefined
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </motion.div>
  );
};

export default NavBar;

import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Stepper from "../components/stepper";
import axios from "axios";

const AdditionalInfo = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();

  const submitApplicationChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        application,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/application/edit/review");
    } catch (error) {
      console.error("Error updating application:", error.message);
    }
  };

  const handleApplicationChange = (event) => {
    updateApplication({
      ...application,
      [event.target.name]: event.target.value,
    });
  };

  const handleNextButtonClick = async () => {
    await submitApplicationChange();
  };


  return (
    <motion.div
      className='flex flex-col w-full h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={6} />
      <hr className='border-zinc-900 w-full' />
      <div className='w-full h-full px-10 flex flex-col justify-between'>
        <div className='w-full'>
          <form>
            <div className='w-full flex flex-col justify-center mt-8 gap-6'>
              <div className=''>
                <label
                  htmlFor='recruiter'
                  className='block text-md font-medium leading-6 text-zinc-900 pl-2'
                >
                  How did you hear about us? *
                </label>
                <div className='mt-2'>
                  <select
                    id='recruiter'
                    name='recruiter'
                    defaultValue={
                      application.recruiter ? application.recruiter : ""
                    }
                    onChange={handleApplicationChange}
                    className='block w-1/3 rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option selected> -- </option>
                    <option value='FAS'>FAS Recruiter</option>
                    <option value='Website'>Website</option>
                    <option value='Instagram'>Instagram</option>
                    <option value='LinkedIn'>LinkedIn</option>
                  </select>
                </div>
              </div>
              {/* Additional Info */}
              <div>
                <label
                  htmlFor='notes'
                  className='block text-md font-medium leading-6 text-zinc-900 pl-2'
                >
                  Additional Notes *
                  <span className='pl-3 font-light text-sm italic'>
                    Feel free to add any information you believe will help your
                    application
                  </span>
                </label>
                <div className='mt-2'>
                  <textarea
                    rows='5'
                    id='notes'
                    name='notes'
                    maxLength='500'
                    className='w-full resize-none border-2 border-zinc-900 rounded-xl shadow-xl p-5'
                    defaultValue={
                      application.notes ? application.notes : ""
                    }
                    onChange={handleApplicationChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          {/* Form Stepper Buttons */}
          <div className='flex flex-row justify-around w-full mt-8 px-10'>
            <button
              className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
              onClick={() =>
                navigate("/dashboard/application/edit/immigration")
              }
            >
              Previous
            </button>

            <button
              className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
              onClick={handleNextButtonClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AdditionalInfo;

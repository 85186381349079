import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

const UserForm = () => {
  const [application, updateApplication] = useState({
    id: null,
    cv: null,
    name: null,
    address: null,
    email: null,
    country: null,
    state: null,
    zip_code: null,
    phone_number: null,
    nationality: null,
    recruiter: null,
    date_of_birth: null,
    gender: null,
    position_id: null,
    license_date_of_issuance: null,
    license_date_of_expiry: null,
    license_details: null,
    specialty: null,
    total_yoe: null,
    notice_period: null,
    highest_educational_certificate: null,
    school: null,
    major: null,
    country_issuance: null,
    four_year: null,
    immigration_status: null,
    visa_status: null,
    visa_details: null,
    marital_status: null,
    number_children: null,
    dl_country: null,
    work_permit: null,
    notes: null,
    created_at: null,
    modified: null,
    forgot_password: null,
    waitingForReview: null,
    status: null,
    updated_at: null,
    completed_at: null,
    deleted: null,
    progressPercentage: null,
    languages: [],
    documents: [],
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`https://api-fly.flyawaystudents.com/api/applicants/${localStorage.getItem("userId")}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        updateApplication(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, []);

  const step = 0;

  return (
    <motion.div
      className='flex h-full w-full p-6 bg-stone-200 p-8'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='flex flex-col w-full p-8 justify-between items-center bg-white rounded-2xl shadow-lg overflow-y-auto'>
        <h1 className='text-3xl font-bold my-5'> My Application</h1>
        {/* Form */}
        <div className='w-full h-full'>
          <Outlet context={[application, updateApplication, step]} />
        </div>
      </div>
    </motion.div>
  );
};

export default UserForm;

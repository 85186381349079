
import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Stepper from "../components/stepper";

const PersonalInfo = () => {
  const [application, updateApplication] = useOutletContext();
  const navigate = useNavigate();

  const submitApplicationChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        application,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/application/edit/cv");
    } catch (error) {
      console.error("Error updating application:", error.message);
    }
  };

  const handleApplicationChange = (event) => {
    updateApplication({
      ...application,
      [event.target.name]: event.target.value,
    });
  };

  const handleNextButtonClick = async () => {
    await submitApplicationChange();
  };


  return (
    <motion.div
      className='flex flex-col h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={0} />
      <hr className='border-zinc-900 w-full' />
      <div className='flex flex-col h-full w-full justify-between px-12'>
        <div className=''>
          <form onSubmit={submitApplicationChange}>
            <div className='flex flex-col justify-between h-full px-12'>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 w-full'>
                {/* Full Name */}
                <div>
                  <label
                    htmlFor='name'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Full Name *
                  </label>
                  <div className='mt-2'>
                    <input
                      id='name'
                      name='name'
                      type='text'
                      value={application.name || ""}
                      onChange={handleApplicationChange}
                      autoComplete='name'
                      required
                      className='block shadow-lg w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Email */}
                <div>
                  <label
                    htmlFor='email'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Email Address *
                  </label>
                  <div className='mt-2'>
                    <input
                      id='email'
                      name='email'
                      type='email'
                      value={application.email || ""}
                      onChange={handleApplicationChange}
                      autoComplete='email'
                      required
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Date of Birth */}
                <div>
                  <label
                    htmlFor='date_of_birth'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Date of Birth *
                  </label>
                  <div className='mt-2'>
                    <input
                      id='date_of_birth'
                      name='date_of_birth'
                      type='date'
                      value={application.date_of_birth || ""}
                      onChange={handleApplicationChange}
                      autoComplete='bday'
                      required
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Phone Number */}
                <div>
                  <label
                    htmlFor='phone_number'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Phone Number *
                  </label>
                  <div className='mt-2'>
                    <input
                      id='phone_number'
                      name='phone_number'
                      type='tel'
                      value={application.phone_number || ""}
                      onChange={handleApplicationChange}
                      autoComplete='tel'
                      required
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Gender */}
                <div>
                  <label
                    htmlFor='gender'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Gender *
                  </label>
                  <div className='mt-2'>
                    <select
                      id='gender'
                      name='gender'
                      value={application.gender || ""}
                      onChange={handleApplicationChange}
                      required
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    >
                      <option value=''>Select your Gender</option>
                      <option value='Male'>Male</option>
                      <option value='Female'>Female</option>
                      <option value='Other'>Other</option>
                    </select>
                  </div>
                </div>

                {/* Address */}
                <div>
                  <label
                    htmlFor='address'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Address
                  </label>
                  <div className='mt-2'>
                    <input
                      id='address'
                      name='address'
                      type='text'
                      value={application.address || ""}
                      onChange={handleApplicationChange}
                      autoComplete='address'
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* State / Province */}
                <div>
                  <label
                    htmlFor='state'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    State / Province
                  </label>
                  <div className='mt-2'>
                    <input
                      id='state'
                      name='state'
                      type='text'
                      value={application.state || ""}
                      onChange={handleApplicationChange}
                      autoComplete='state'
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* City */}
                <div>
                  <label
                    htmlFor='city'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    City
                  </label>
                  <div className='mt-2'>
                    <input
                      id='city'
                      name='city'
                      type='text'
                      value={application.city || ""}
                      onChange={handleApplicationChange}
                      autoComplete='city'
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Country */}
                <div>
                  <label
                    htmlFor='country'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Country *
                  </label>
                  <div className='mt-2'>
                    <input
                      id='country'
                      name='country'
                      type='text'
                      value={application.country || ""}
                      onChange={handleApplicationChange}
                      autoComplete='country'
                      required
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>

                {/* Zip Code */}
                <div>
                  <label
                    htmlFor='zip_code'
                    className='block text-sm font-medium leading-6 text-zinc-900'
                  >
                    Zip Code / Postal Code
                  </label>
                  <div className='mt-2'>
                    <input
                      id='zip_code'
                      name='zip_code'
                      type='text'
                      value={application.zip_code || ""}
                      onChange={handleApplicationChange}
                      autoComplete='postal-code'
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

            {/* Form Stepper Buttons */}
            <div className='flex flex-row justify-around w-full mt-8 px-10'>
          <button
            className='bg-zinc-500 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold transition-all'
            disabled
          >
            Previous
          </button>

          <button
            className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
            onClick={handleNextButtonClick} // Call handleNextButtonClick instead of navigate directly
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default PersonalInfo;
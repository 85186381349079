// Função para fazer a requisição ao endpoint
export const fetchAllApplicants = async () => {
  try {
    // Obtendo o token de autorização do localStorage
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://api-fly.flyawaystudents.com/api/applicants",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching applicants:", error);
  }
};

export const fetchUnqualifiedApplicants = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://api-fly.flyawaystudents.com/api/applicants/unqualified",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching applicants:", error);
  }
};

export const fetchWaitingForReviewApplicants = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://api-fly.flyawaystudents.com/api/applicants?waitingForReview=1",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching applicants:", error);
  }
};

export const fetchCompletedApplicants = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://api-fly.flyawaystudents.com/api/applicants?completed_at=1",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching applicants:", error);
  }
};

export const fetchInProgressApplicants = async () => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      "https://api-fly.flyawaystudents.com/api/applicants?waitingForReview=0",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching applicants:", error);
  }
};

export const fetchApplicantById = async (applicantId) => {
  try {
    const token = localStorage.getItem("token");

    const response = await fetch(
      `https://api-fly.flyawaystudents.com/api/applicant/${applicantId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }

    const data = await response.json();
    const formattedApplicant = createApplicant(data); // Chamando createApplicant para formatar os dados

    return formattedApplicant; // Retornando o aplicante formatado
  } catch (error) {
    console.error("Error fetching applicants:", error);
    return null; // Retornando null em caso de erro
  }
};

export const createApplicant = (applicant) => {
  const newApplicant = {
    id: applicant.id || null,
    cv: applicant.cv || null,
    name: applicant.name || "",
    address: applicant.address || "",
    email: applicant.email || "",
    country: applicant.country || "",
    state: applicant.state || "",
    zip_code: applicant.zip_code || "",
    phone_number: applicant.phone_number || "",
    nationality: applicant.nationality || "",
    recruiter: applicant.recruiter || "",
    date_of_birth: applicant.date_of_birth || "",
    gender: applicant.gender || "",
    position_id: applicant.position_id || "",
    license_date_of_issuance: applicant.license_date_of_issuance || "",
    license_date_of_expiry: applicant.license_date_of_expiry || "",
    license_details: applicant.license_details || "",
    specialty: applicant.specialty || "",
    total_yoe: applicant.total_yoe || 0,
    notice_period: applicant.notice_period || 0,
    highest_educational_certificate:
      applicant.highest_educational_certificate || "",
    school: applicant.school || "",
    major: applicant.major || "",
    country_issuance: applicant.country_issuance || "",
    four_year: applicant.four_year || false,
    immigration_status: applicant.immigration_status || "",
    visa_status: applicant.visa_status || "",
    visa_details: applicant.visa_details || "",
    marital_status: applicant.marital_status || "",
    number_children: applicant.number_children || 0,
    dl_country: applicant.dl_country || "",
    work_permit: applicant.work_permit || false,
    languages: applicant.languages || [],
    documents: applicant.documents || [],
    notes: applicant.notes || "",
    password: applicant.password || "",
    created_at: applicant.created_at || "",
    modified: applicant.modified || false,
    forgot_password: applicant.forgot_password || false,
    forgot_token: applicant.forgot_token || null,
    waiting_for_review: applicant.waiting_for_review || false,
    status: applicant.status || null,
    updated_at: applicant.updated_at || "",
    deleted: applicant.deleted || false,
  };

  //console.log("New Applicant Added:", newApplicant);
  return newApplicant;
};

import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import Stepper from "../components/stepper";

const EducationInfo = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();

  const submitApplicationChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        application,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/application/edit/languages");
    } catch (error) {
      console.error("Error updating application:", error.message);
    }
  };

  const handleApplicationChange = (event) => {
    updateApplication({
      ...application,
      [event.target.name]: event.target.value,
    });
  };


  const handleNextButtonClick = async () => {
    await submitApplicationChange();
  };


  return (
    <motion.div
      className='flex flex-col h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={2} />
      <hr className='border-zinc-900 w-full' />
      <div className='w-full h-full flex flex-col justify-between px-12'>
        <div>
          <form className='w-full h-full'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 w-full'>
              {/* University/School */}
              <div>
                <label
                  htmlFor='school'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  University / School *
                </label>
                <div className='mt-2'>
                  <input
                    id='school'
                    name='school'
                    type='text'
                    defaultValue={application.school ? application.school : ""}
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Highest Educational Certificate */}
              <div>
                <label
                  htmlFor='highest_educational_certificate'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Highest Educational Certificate *
                </label>
                <div className='mt-2'>
                  <select
                    id='highest_educational_certificate'
                    onChange={handleApplicationChange}
                    name='highest_educational_certificate'
                    defaultValue={
                      application.highest_educational_certificate
                        ? application.highest_educational_certificate
                        : ""
                    }
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value='' selected>
                      Select your current highest educational certificate
                    </option>
                    <option value='phd'>PhD or equivalent</option>
                    <option value='masters'>Masters or equivalent</option>
                    <option value='bachelors'>Bachelors or equivalent</option>
                    <option value='highschool'>
                      Highschool Diploma or equivalent
                    </option>
                  </select>
                </div>
              </div>

              {/* Country of Issuance */}
              <div>
                <label
                  htmlFor='country_issuance'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Country of Issuance *
                </label>
                <div className='mt-2'>
                  <input
                    id='country_issuance'
                    name='country_issuance'
                    type='text'
                    onChange={handleApplicationChange}
                    defaultValue={
                      application.country_issuance
                        ? application.country_issuance
                        : ""
                    }
                    autoComplete='country-name'
                    required
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Major */}
              <div>
                <label
                  htmlFor='major'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Major *
                </label>
                <div className='mt-2'>
                  <input
                    id='major'
                    name='major'
                    type='text'
                    onChange={handleApplicationChange}
                    defaultValue={application.major ? application.major : ""}
                    required
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Four Year */}
              <div>
                <label
                  htmlFor='four_year'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  4 year degree? *
                  <span className='font-light ml-4'>
                    (Is your course designed to take 4 years or longer?)
                  </span>
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='four_year'
                    onChange={handleApplicationChange}
                    name='four_year'
                    defaultValue={
                      application.four_year ? application.four_year : ""
                    }
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value=''> -- </option>
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Form Stepper Buttons */}
        <div className='flex flex-row justify-around w-full mt-8 px-10'>
          <button
            className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
            onClick={() => navigate("/dashboard/application/edit/cv")}
          >
            Previous
          </button>

          <button
            className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
            onClick={handleNextButtonClick}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default EducationInfo;

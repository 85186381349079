import {
  Cog6ToothIcon,
  Squares2X2Icon,
  TableCellsIcon,
  DocumentCheckIcon,
  ArchiveBoxXMarkIcon,
  PlayIcon,
  ClockIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import { useOutlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import SideBar, {
  NestedSidebarListItem,
  SidebarItem,
} from "../components/navigation/side-bar";
import { fetchAllApplicants } from "../components/management/applications/applicationService";

import {
  BarChart,
  Bar,
  Pie,
  PieChart,
  Cell,
  XAxis,
  YAxis,
  //ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
} from "recharts";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const statusNames = [
  "Applied",
  "Pre-Qualified",
  "Language",
  "CGFNS",
  "NCLEX",
  "Visa Cred",
  "Job Search",
  "Work Visa",
  "Completion",
  "Stagnant",
];

const renderCustomAxisTick = ({ x, y, payload }) => {
  let stepName = "";

  stepName = statusNames[payload.value - 1];

  return (
    <g transform={`translate(${x + 27},${y})`}>
      <text x={0} y={0} dy={16} textAnchor='end' fill='#666'>
        {stepName}
      </text>
    </g>
  );
};

const COLORS = [
  "rgb(113 113 122)",
  "rgb(239 68 68)",
  "rgb(249 115 22)",
  "rgb(234 179 8)",
  "rgb(132 204 22)",
  "rgb(34 197 94)",
  "rgb(20 184 166)",
  "rgb(59 130 246)",
  "rgb(99 102 241)",
  "rgb(139 92 246)",
];

const ManagementDashboard = () => {
  const outlet = useOutlet();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const applicants = await fetchAllApplicants();
      applicants ? setData(applicants) : setData([]);
    };

    fetchData();
  }, []);

  const scatterData = data.map((application) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return {
      currentStep: application.status,
      timeOnPlatform: Math.round(
        Math.abs((Date.parse(application.created_at) - new Date()) / oneDay),
      ),
      id: application.id,
    };
  });

  const statusPercentageData = statusNames.map((statusName, index) => {
    const count = data.filter(
      (application) => application.status - 1 == index,
    ).length;

    return {
      name: statusName,
      value: count,
    };
  });

  const barChartData = () => {
    const today = new Date();

    let currentMonth = today.getMonth();
    const last12Months = months
      .slice(currentMonth - 12)
      .concat(months.slice(0, currentMonth));

    let barData = [];

    for (let i = 0; i < 12; i++) {
      const inboundAppsCount = data.filter((application) => {
        const dateArray = application.created_at.split("-");
        const appMonth = parseInt(dateArray[1], 10) - 1;
        const appYear = parseInt(dateArray[0], 10);

        return appMonth - i > 0
          ? appYear == today.getFullYear() && appMonth == today.getMonth() - i
          : appYear == today.getFullYear() - 1 &&
              appMonth == today.getMonth() - i;
      }).length;

      barData.push({
        name: last12Months[i],
        inbound: inboundAppsCount,
      });
    }

    return barData;
  };

  console.log(scatterData);

  const ScatterTooltip = ({ active, payload }) => {
    console.log(payload);
    if (active && payload && payload.length) {
      return (
        <div className='bg-white rounded-lg shadow-lg p-3 transition-all'>
          <p className=''>{`ID: ${payload[0].payload.id}`}</p>
          <p className=''>{`Current Step: ${statusNames[payload[0].payload.currentStep - 1]}`}</p>
          <p className=''>{`Time On Platform: ${payload[0].payload.timeOnPlatform} days`}</p>
        </div>
      );
    }

    return null;
  };

  const renderScatterChart = (
    <ResponsiveContainer width='100%' height='100%'>
      <ScatterChart
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis
          type='number'
          dataKey='currentStep'
          name='currentStep'
          tick={renderCustomAxisTick}
        />
        <YAxis
          type='number'
          dataKey='timeOnPlatform'
          name='timeOnPlatform'
          unit=' days'
        />
        <Tooltip
          cursor={{ strokeDasharray: "3 3" }}
          content={<ScatterTooltip />}
        />
        <Scatter
          name='FAS Scatter Graph'
          data={scatterData}
          fill='rgb(248 69 69)'
        />
      </ScatterChart>
    </ResponsiveContainer>
  );

  const renderLineChart = (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={barChartData()}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' reversed />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey='inbound' stackId='a' fill='rgb(8 145 178)' />
      </BarChart>
    </ResponsiveContainer>
  );

  const renderPieChart = (
    <ResponsiveContainer width='100%' height='100%'>
      <PieChart>
        <Pie
          data={statusPercentageData}
          cx='50%'
          cy='50%'
          labelLine={false}
          label
          outerRadius={140}
          fill='#8884d8'
          dataKey='value'
        >
          {statusPercentageData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip contentStyle={{ borderRadius: "0.75rem" }} />
      </PieChart>
    </ResponsiveContainer>
  );

  return (
    <div className='h-full min-w-[600px] select-none'>
      <div className='flex h-full'>
        <SideBar>
          <SidebarItem
            icon={<Squares2X2Icon width={20} />}
            text='Dashboard'
            href='/management'
          />
          <hr className='my-3' />
          <NestedSidebarListItem
            headChild={{ icon: <InboxIcon width={20} />, text: "Applications" }}
          >
            <SidebarItem
              icon={<TableCellsIcon width={20} />}
              text='All Applications'
              href='/management/applications/all'
            />
            <SidebarItem
              icon={<ArchiveBoxXMarkIcon width={20} />}
              text='Unqualified'
              href='/management/applications/unqualified'
            />
            <SidebarItem
              icon={<PlayIcon width={20} />}
              text='In Progress'
              href='/management/applications/inprogress'
            />
            <SidebarItem
              icon={<ClockIcon width={20} />}
              text='Waiting For Review'
              href='/management/applications/waitingforreview'
            />
            <SidebarItem
              icon={<DocumentCheckIcon width={20} />}
              text='Completed'
              href='/management/applications/completed'
            />
          </NestedSidebarListItem>
          <hr className='my-3' />
          <SidebarItem
            icon={<Cog6ToothIcon width={20} />}
            text='Settings'
            href='/management/settings'
          />
        </SideBar>
        {outlet || (
          <motion.div
            className='w-full h-full bg-stone-200 p-8 overflow-y-auto'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <span className='p-5 text-4xl font-bold'>Dashboard</span>
            <div className='grid grid-cols-4 gap-8 p-2 mt-5'>
              <div className='flex flex-col justify-center items-center col-span-4 lg:col-span-2 2xl:col-span-1 p-6 shadow-lg rounded-2xl bg-white h-96 w-full'>
                <span className='text-lg font-bold'>Inbound Applications</span>
                {renderLineChart}
              </div>
              <div className='flex flex-col justify-center items-center col-span-4 lg:col-span-2 2xl:col-span-1 p-5 shadow-lg rounded-2xl bg-white h-96 w-full'>
                <span className='text-lg font-bold'>
                  Time on Platform Vs Current Step
                </span>
                {renderScatterChart}
              </div>
              <div className='flex flex-col justify-center items-center col-span-4 p-5 shadow-lg rounded-2xl bg-white h-[460px] pt-8 w-full'>
                <span className='text-lg font-bold'>
                  Applications by Current Step
                </span>
                {renderPieChart}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ManagementDashboard;

import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

const Accordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const accordionTitle = "Frequently Asked Questions";

  const items = [
    {
      title: "Do I need prior nursing experience to apply for your services?",
      content:
        "No, we welcome applicants with and without prior nursing experience. However, certain requirements must be met, such as education and language proficiency.",
    },
    {
      title:
        "What are the education requirements for nurses applying through your program?",
      content:
        "Nurses must have graduated from an accredited Registered Nursing program, such as an Associate Degree in Nursing (ADN) or Bachelor of Science in Nursing (BSN).",
    },
    {
      title: "Do I need to pass an English language proficiency test?",
      content:
        "Yes, depending on your country of origin, you may need to take and pass the TOEFL, TOEIC, or IELTS. Results are sent directly to the state nursing board.",
    },
    {
      title:
        "How long does the entire process take, from application to job placement?",
      content:
        "The timeline can vary depending on individual circumstances, but we strive to expedite the process as much as possible. On average, it can take several months to complete all the necessary steps.",
    },
    {
      title: "Are there any fees associated with your services?",
      content:
        "No, our services are entirely free for nurses and students aiming to become nurses and doctors. We get paid directly by hospitals for supplying them with validated foreign nurses.",
    },
    {
      title:
        "Can you help me find a job placement in a specific state or city in the USA?",
      content:
        "Yes, we work with hospitals across the United States and can help you find a job placement in a location that suits your preferences and qualifications.",
    },
    {
      title: "What types of work visas are available for nurses?",
      content:
        "There are several types of work visas available, including the H-1B Temporary Work Visa and the EB-3 Visa (green card). The specific visa you may be eligible for depends on your qualifications and the role you'll fulfill.",
    },
    {
      title: "What support do you provide during the visa application process?",
      content:
        "We provide comprehensive support throughout the visa application process, including guidance on completing paperwork accurately and finding the best nursing position to fit your abilities.",
    },
    {
      title:
        "Do I need to secure a job offer from a US-based employer before applying for your services?",
      content:
        "No, you do not need to secure a job offer before applying. We can help you find a job placement once you have completed all the necessary requirements.",
    },
    {
      title: "Can I bring my family with me to the USA on a work visa?",
      content:
        "Depending on the type of visa you are eligible for, you may be able to bring your spouse and children under 21 to the United States. Visa requirements vary, so it's important to check with us for specific details.",
    },
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleRotate = (index) => {
    return activeIndex === index ? "rotate-180" : "";
  };

  return (
    <main className='p-5'>
      <div className='flex justify-center items-start my-2'>
        <div className='w-full sm:w-10/12 md:w-1/2 my-1'>
          <h2 className='text-3xl font-semibold mb-6'>{accordionTitle}</h2>
          <ul className='flex flex-col'>
            {items.map((item, index) => (
              <li
                key={index}
                className='bg-white my-2 shadow-lg rounded-xl p-4'
              >
                <div
                  className='flex flex-row justify-between cursor-pointer'
                  onClick={() => handleToggle(index)}
                >
                  <h2 className='flex flex-row justify-between items-center font-semibold p-3 cursor-pointer'>
                    <span>{item.title}</span>
                  </h2>
                  <ChevronDownIcon
                    width={20}
                    className={`${handleRotate(index)} transition-all`}
                  />
                </div>
                <div
                  className='border-l-2 border-red-600 overflow-hidden max-h-0 duration-500 transition-all'
                  style={activeIndex === index ? { maxHeight: "1000px" } : {}}
                >
                  <p className='p-3 text-gray-900'>{item.content}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default Accordion;

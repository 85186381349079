import SideBar, { SidebarItem } from "../components/navigation/side-bar-user";
import { fetchApplicantById } from "../components/management/applications/applicationService";
import { useOutlet, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  DocumentCheckIcon,
  DocumentTextIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/solid";

const UserDashboard = () => {
  const outlet = useOutlet();
  const [applicant, setApplicant] = useState({});

  useEffect((id) => {
    const fetchData = async () => {
      let application = await fetchApplicantById(id);
      setApplicant(application);
    };

    fetchData();
  }, []);

  return (
    <motion.div
      className='h-full min-w-[600px] select-none'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className='flex h-full'>
        <SideBar>
          <SidebarItem
            icon={<Squares2X2Icon width={20} />}
            text='Dashboard'
            href='/dashboard'
          />
          <hr className='my-3' />

          <SidebarItem
            icon={<DocumentTextIcon width={20} />}
            text='My Application'
            href='/dashboard/application'
          />
          <SidebarItem
            icon={<DocumentCheckIcon width={20} />}
            text='Application Status'
            href='/dashboard/applicationstatus'
          />
        </SideBar>
        {outlet || (
          <motion.div
            className='w-full h-full bg-stone-200 overflow-y-auto'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className='p-8'>
              <span className='p-5 text-4xl font-bold'>Dashboard</span>
              <div className='w-full h-full grid grid-cols-2 gap-24'>
                <div className='col-span-2 flex justify-center items-center'>
                  {!applicant?.date_of_birth ? (
                    <div className='text-3xl'>
                      <Link to='/dashboard/application/edit/personal'>
                        <div className='bg-red-600 px-12 text-white py-6 rounded-full font-semibold shadow-lg hover:brightness-75 transition-all'>
                          Start your Application
                        </div>
                      </Link>
                    </div>
                  ) : (
                    <div className='text-3xl font-light text-gray-400'>
                      Your application is already in progress. Feel free to
                      update it at My Application.
                    </div>
                  )}
                </div>
                <div>
                  <Link to='/dashboard/application'>
                    <div className='bg-red-600 shadow-lg border-red-700 border p-20 rounded-xl text-white text-2xl font-bold hover:brightness-75 cursor-pointer text-center transition-all'>
                      My Application
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to='/dashboard/applicationstatus'>
                    <div className='bg-red-600 shadow-lg border-red-700 border p-20 rounded-xl text-white text-2xl font-bold hover:brightness-75 cursor-pointer text-center transition-all'>
                      Application Status
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};

export default UserDashboard;

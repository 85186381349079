import logo from "../../images/logo-no-bird.png";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { XCircleIcon } from "@heroicons/react/24/solid";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api-fly.flyawaystudents.com/api/applicants/login",
        formData,
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("userName", response.data.user.name);
      localStorage.setItem("userEmail", response.data.user.email);

      navigate("/dashboard/application");
    } catch (error) {
      console.error("Error:", error.message);
      // Lidar com erros de login
      setError("Invalid Login Credentials. Please try again");
    }
  };

  return (
    <motion.div
      className='flex h-full flex-1 flex-col justify-center bg-gray-100 items-center px-6 py-12 lg:px-8 min-w-sm'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Company Log o*/}
      <div className='sm:flex sm:mx-auto sm:w-full sm:max-w-sm'>
        <img
          className='mx-auto h-14 w-auto'
          src={logo}
          alt='Fly Away Students Logo'
        />
      </div>

      {/* Login Form Box */}
      <div className='p-10 mt-10 w-full min-w-sm max-w-lg rounded-2xl shadow-2xl border-2 border-zinc-900 bg-white'>
        <h2 className='mb-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
          Sign in to your account
        </h2>

        {/* Login Form */}
        <form className='space-y-6' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                className={`block w-full rounded-md border-0 ${error ? "border-2 border-red-400" : ""} p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose`}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Password
              </label>
              <div className='text-sm'>
                <Link
                  to='/forgot'
                  className='font-semibold text-red-600 hover:text-red-500'
                >
                  Forgot password?
                </Link>
              </div>
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className={`block w-full rounded-md border-0 ${error ? "border-2 border-red-400" : ""} p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose`}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
            >
              Sign in
            </button>
          </div>
        </form>

        {error && (
          <div className='flex flex-row justify-center mt-8'>
            <XCircleIcon width={24} className='text-red-600' />
            <p className='ml-2 p-2 text-red-600'>{error}</p>
          </div>
        )}

        <p className='mt-10 text-center text-md text-gray-500'>
          Not a member?{" "}
          <Link
            to='/register'
            className='font-semibold leading-6 text-red-600 hover:text-red-500 ml-2'
          >
            Register Now
          </Link>
        </p>
      </div>
    </motion.div>
  );
};

export default LoginForm;

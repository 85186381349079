import {
  AcademicCapIcon,
  BriefcaseIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  GlobeAmericasIcon,
  InboxArrowDownIcon,
  CheckIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/solid";

const Stepper = ({ currIndex }) => {
  const steps = [
    {
      title: "Personal Info",
      icon: <DocumentTextIcon width={22} />,
    },
    {
      title: "Resume/CV",
      icon: <InboxArrowDownIcon width={22} />,
    },
    {
      title: "Education",
      icon: <AcademicCapIcon width={22} />,
    },
    {
      title: "Languages",
      icon: <SpeakerWaveIcon width={22} />,
    },
    {
      title: "Work",
      icon: <BriefcaseIcon width={22} />,
    },
    {
      title: "Immigration",
      icon: <GlobeAmericasIcon width={22} />,
    },
    {
      title: "Additional",
      icon: <DocumentPlusIcon width={22} />,
    },
    {
      title: "Review",
      icon: <CheckIcon width={22} />,
    },
  ];
  return (
    <>
      {/* Stepper */}
      <div className='flex flex-row w-full justify-around my-8 text-center'>
        {steps.map((step, index) => {
          return (
            <div
              key={step.title}
              className='flex flex-col justify-center items-center'
            >
              <div
                className={`p-2 shadow-lg rounded-full text-white ${currIndex > index ? "bg-red-600" : "bg-zinc-500"} ${currIndex >= index ? "border-2 border-red-600" : "border-2 border-zinc-600"}`}
              >
                {step.icon}
              </div>
              <div className='hidden lg:flex mt-4 font-semibold justify-center items-center text-center align-middle text-wrap'>
                {step.title}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Stepper;

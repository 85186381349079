const YoeCell = ({ info }) => {
  return (
    <div className='w-full flex justify-center mr-8'>
      <span
        className={`px-4 py-1 rounded-full text-white text-sm font-semibold ${info > 7 ? "bg-green-500" : info <= 7 && info > 3 ? "bg-yellow-500" : info <= 3 && info > 1 ? "bg-orange-500" : "bg-red-500"}`}
      >
        {info ? info : "N/A"}
      </span>
    </div>
  );
};

export default YoeCell;

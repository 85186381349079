import { useState } from 'react';

const ManagementSettings = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    newPassword: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    try {
      const response = await fetch('https://api-fly.flyawaystudents.com/api/auth/changePassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          ...passwordData,
          id: userId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to change password');
      }

      // Handle success response
      console.log('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error.message);
    }
  };

  const handleManagerInvite = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem('token');

    try {
      const response = await fetch('https://api-fly.flyawaystudents.com/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to invite manager');
      }

      // Handle success response
      console.log('Manager invited successfully');
    } catch (error) {
      console.error('Error inviting manager:', error.message);
    }
  };

  return (
    <div className='w-full h-full flex'>
      <div className='flex flex-col w-full p-10'>
        <div className='flex'>
          <h1 className='text-5xl font-bold'>Settings</h1>
        </div>
        <hr className='mb-5 mt-3 border-zinc-900' />
        <div className='w-full h-full flex flex-col gap-12'>
          <div className='p-8'>
            <div className='text-2xl font-semibold pl-3'>
              Create New Manager Account
            </div>
            <hr className='mb-5 mt-3 border-zinc-900' />
            <div className='w-full'>
              <form onSubmit={handleManagerInvite}>
                <div className='grid grid-cols-4 justify-around items-center gap-12 w-full'>
                  {/* Manager Name */}
                  <div>
                    <label
                      htmlFor='name'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Name *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* Manager Email */}
                  <div>
                    <label
                      htmlFor='email'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Email *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* Manager Phone */}
                  <div>
                    <label
                      htmlFor='phone'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Phone *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='phone'
                        name='phone'
                        type='tel'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* Manager Password */}
                  <div>
                    <label
                      htmlFor='password'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Manager Password *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='password'
                        name='password'
                        type='password'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                      />
                    </div>
                  </div>
                  <button
                    className='h-1/2 bg-red-600 rounded-full px-10 shadow-md hover:brightness-75 transition-all text-white font-semibold'
                    type='submit'
                  >
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className='p-8'>
            <div className='text-2xl font-semibold pl-3'>
              Change Password
            </div>
            <hr className='mb-5 mt-3 border-zinc-900' />
            <div className='w-full'>
              <form onSubmit={handlePasswordChange}>
                <div className='grid grid-cols-1 justify-around gap-12 w-full'>
                  {/* Current Password */}
                  <div>
                    <label
                      htmlFor='currentPassword'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      Current Password *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='currentPassword'
                        name='currentPassword'
                        type='password'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                        onChange={(e) => setPasswordData({ ...passwordData, currentPassword: e.target.value })}
                      />
                    </div>
                  </div>
                  {/* New Password */}
                  <div>
                    <label
                      htmlFor='newPassword'
                      className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                    >
                      New Password *
                    </label>
                    <div className='mt-2'>
                      <input
                        id='newPassword'
                        name='newPassword'
                        type='password'
                        required
                        className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                        onChange={(e) => setPasswordData({ ...passwordData, newPassword: e.target.value })}
                      />
                    </div>
                  </div>
                  <button
                    className='bg-red-600 rounded-full px-10 shadow-md hover:brightness-75 transition-all text-white font-semibold'
                    type='submit'
                  >
                    Change
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementSettings;
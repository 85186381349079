const ProgressCell = ({ info }) => {
  const stepPercentages = [0, 12.5, 25, 37.5, 50, 62.5, 75, 87.5, 100];

  if (info == null || info == "") {
    info = 0;
  }

  return (
    <div
      className='flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700'
      role='progressbar'
      aria-valuenow={info}
      aria-valuemin='0'
      aria-valuemax='100'
    >
      <div
        className='flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500'
        style={{ width: `${stepPercentages[info - 1]}%` }}
      ></div>
    </div>
  );
};

export default ProgressCell;

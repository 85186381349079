import { PencilIcon } from "@heroicons/react/24/solid";
import { motion } from "framer-motion";
import { useOutlet, Link } from "react-router-dom";
import { DocumentArrowDownIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { useState, useEffect } from "react";
import { LanguageListItem } from "../../../management/table/components/application-modal";
import Loading from "../../../misc/loading";

const ViewApplication = () => {
  const [application, setApplication] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api-fly.flyawaystudents.com/api/applicants/${localStorage.getItem("userId")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setApplication(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, []);

  const outlet = useOutlet();

  if (!application) {
    return <Loading />;
  }

  return (
    <motion.div
      className='h-full w-full p-6 bg-gray-100 p-8'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {outlet || (
        <div className='h-full w-full rounded-xl bg-white shadow-lg p-10 overflow-y-auto'>
          <div className='flex flex-col'>
            <div className='flex flex-row justify-between'>
              <h1 className='align-bottom text-4xl font-semibold drop-shadow-lg'>
                My Application
              </h1>
              <Link to='/dashboard/application/edit/personal'>
                <div className='flex justify-center gap-3 text-lg font-semibold rounded-full p-3 bg-red-600 shadow-lg text-white hover:brightness-75 hover:scale-105'>
                  <PencilIcon width={32} />
                </div>
              </Link>
            </div>
            <div className='w-full h-full mt-10'>
              <div className='grid grid-cols-2 gap-4 border-2 border-zinc-900 shadow-lg mb-6 rounded-xl p-4 bg-white'>
                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Personal Information
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-2'>
                    {/* Field Name */}
                    <div className='flex flex-row text-lg font-bold'>
                      Name:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.name}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Gender:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.gender}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Email:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.email}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Date Of Birth:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.date_of_birth}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Phone Number:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.phone_number}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Address:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.address}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      City:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.city}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      State / County:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.state}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Zip Code:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.zip_code}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Country:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.country}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2 lg:col-span-1'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Resume / CV
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 p-6 gap-2 h-full'>
                    <div className='flex flex-row justify-center items-center'>
                      <a
                        href={application.cv}
                        target='_blank'
                        rel='noreferrer noopener'
                        className='text-black'
                      >
                        <div
                          className={`${application.cv ? "visible" : "hidden"} flex flex-col items-center justify-center rounded-lg border-2 shadow-lg p-5 lg:p-10 bg-white hover:brightness-75 cursor-pointer gap-3`}
                        >
                          <h4 className='font-bold text-lg'>Current Resume</h4>

                          <DocumentArrowDownIcon width={30} />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                {/* Inner Card */}
                <div className='col-span-2 lg:col-span-1 flex flex-col border border-zinc-300 rounded-lg shadow-lg '>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Languages
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 p-6 gap-2'>
                    <div className='flex flex-row justify-center items-center'>
                      <div className='grid grid-cols-3 gap-6 p-5'>
                        {application.languages.map((language) => {
                          return (
                            <LanguageListItem
                              key={language.id}
                              language={language}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Education
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-2'>
                    {/* Field Name */}
                    <div className='flex flex-row text-lg font-bold'>
                      University/School:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.school}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Highest Educational Certificate:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.highest_educational_certificate}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Country of Issuance:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.country_issuance}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Major:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.major}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      4 Year Degree?:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.four_year ? <div>Yes</div> : <div>No</div>}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Work
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-2'>
                    {/* Field Name */}
                    <div className='flex flex-row text-lg font-bold'>
                      Profession:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.position_id === 0
                          ? "Doctor"
                          : application.position_id === 1
                            ? "Nurse"
                            : application.position_id === 2
                              ? "Technician"
                              : "Other"}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Years of Experience:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.total_yoe}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      License Issued Date:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.license_date_of_issuance}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      License Expiration Date:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.license_date_of_expiry}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      License Details:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.license_details}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Specialty:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.specialty}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Notice Period:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.notice_period} days
                      </span>
                    </div>
                  </div>
                </div>

                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Immigration
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-2'>
                    {/* Field Name */}
                    <div className='flex flex-row text-lg font-bold'>
                      Immigration Status:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.immigration_status === 0
                          ? "Citizen"
                          : application.immigration_status === 1
                            ? "Greencard"
                            : application.immigration_status === 2
                              ? "Foreign"
                              : "Other"}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Visa Status:
                      {/* Field Data */}
                      <span className='text-md font-light pl-2'>
                        {application.visa_status === 0
                          ? "J-1 Visa"
                          : application.visa_status === 1
                            ? "H1-B"
                            : application.visa_status === 2
                              ? "EB-3"
                              : application.visa_status === 3
                                ? "Other Visa"
                                : "No Visa"}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Nationality:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.nationality}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Marital Status:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.marital_status}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Number of Children:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.number_children}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Driver License Issuing Country:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.dl_country}
                      </span>
                    </div>
                  </div>
                </div>

                {/* Inner Card */}
                <div className='flex flex-col border border-zinc-300 rounded-lg shadow-lg col-span-2'>
                  {/* Card Title */}
                  <span className='w-full bg-black text-white rounded-t-lg p-2 px-5 font-bold shadow-lg'>
                    Immigration
                  </span>
                  {/* Card Body */}
                  <div className='grid grid-cols-1 lg:grid-cols-2 p-6 gap-2'>
                    <div className='flex flex-row text-lg font-bold'>
                      Recruiter:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.recruiter}
                      </span>
                    </div>
                    <div className='flex flex-row text-lg font-bold'>
                      Additional Notes:{" "}
                      <span className='text-md font-light pl-2'>
                        {application.notes}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ViewApplication;

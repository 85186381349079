const VisaStatusCell = ({ info }) => {
  return (
    <div className='w-full flex justify-center'>
      <span
        className={`rounded-full whitespace-pre text-white text-center text-sm font-semibold px-4 py-1 ${info == 0 ? "bg-green-500" : info == 1 ? "bg-blue-500" : info == 2 ? "bg-red-500" : "bg-gray-500"}`}
      >
        {info
          ? info == 0
            ? "Citizen"
            : info == 1
              ? "Greencard Holder"
              : info == 2
                ? "Foreign"
                : "Other"
          : "N/A"}
      </span>
    </div>
  );
};

export default VisaStatusCell;

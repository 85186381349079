import logo from "../../images/logo-no-bird.png";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";

const ForgotForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    passwordConfirm: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.password !== formData.passwordConfirm) {
        throw new Error("Passwords do not match");
      }

      const response = await axios.post(
        "https://api-fly.flyawaystudents.com/api/applicants/register",
        formData,
      );

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("userName", response.data.user.name);
      localStorage.setItem("userEmail", response.data.user.email);
      navigate("/dashboard/application/edit/personal");
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  return (
    // Container
    <motion.div
      className='flex h-full flex-1 flex-col justify-center items-center px-6 py-12 lg:px-8 min-w-sm'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Company Log o*/}
      <div className='sm:flex sm:mx-auto sm:w-full sm:max-w-sm'>
        <img
          className='mx-auto h-14 w-auto'
          src={logo}
          alt='Fly Away Students Logo'
        />
      </div>

      {/* Register Form Box */}
      <div className='p-10 mt-10 w-full min-w-sm max-w-lg rounded-2xl shadow-2xl border-2 border-zinc-900'>
        <h2 className='mb-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
          Forgot Password?
        </h2>

        {/* Register Form */}
        <form className='space-y-6' onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
            >
              Send Password Reset Link
            </button>
          </div>
        </form>

        <p className='mt-10 text-center text-md text-gray-500'>
          Remember your password?{" "}
          <Link
            to='/login'
            className='font-semibold leading-6 text-red-600 hover:text-red-500 ml-2'
          >
            Log In
          </Link>
        </p>
      </div>
    </motion.div>
  );
};

export default ForgotForm;

import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Stepper from "../components/stepper";
import axios from "axios";

const WorkInfo = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();

  const submitApplicationChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        application,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/application/edit/immigration");
    } catch (error) {
      console.error("Error updating application:", error.message);
    }
  };

  const handleApplicationChange = (event) => {
    updateApplication({
      ...application,
      [event.target.name]: event.target.value,
    });
  };

  const handleNextButtonClick = async () => {
    await submitApplicationChange();
  };


  return (
    <motion.div
      className='flex flex-col w-full h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={4} />
      <hr className='border-zinc-900 w-full' />
      <div className='w-full h-full px-10 flex flex-col justify-between'>
        <div>
          <form className='w-full'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 w-full'>
              {/* Profession */}
              <div>
                <label
                  htmlFor='position_id'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Profession *
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='position_id'
                    onChange={handleApplicationChange}
                    name='position_id'
                    defaultValue={
                      application.position_id ? application.position_id : ""
                    }
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={0}>Doctor</option>
                    <option value={1}>Nurse</option>
                    <option value={2}>Technician</option>
                    <option value={3}>Other</option>
                  </select>
                </div>
              </div>

              {/* Total YOE */}
              <div>
                <label
                  htmlFor='name'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Years of Professional Experience *
                </label>
                <div className='mt-2'>
                  <input
                    id='name'
                    name='total_yoe'
                    type='number'
                    defaultValue={
                      application.total_yoe ? application.total_yoe : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* License Issued Date */}
              <div>
                <label
                  htmlFor='license_date_of_issuance'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  License Issued Date *
                </label>
                <div className='mt-2'>
                  <input
                    id='license_date_of_issuance'
                    name='license_date_of_issuance'
                    type='date'
                    defaultValue={
                      application.license_date_of_issuance
                        ? application.license_date_of_issuance
                        : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* License Expiration Date */}
              <div>
                <label
                  htmlFor='license_date_of_expiry'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  License Expiration Date *
                </label>
                <div className='mt-2'>
                  <input
                    id='license_date_of_expiry'
                    name='license_date_of_expiry'
                    type='date'
                    defaultValue={
                      application.license_date_of_expiry
                        ? application.license_date_of_expiry
                        : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* License Details */}
              <div>
                <label
                  htmlFor='license_details'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  License Details
                </label>
                <div className='mt-2'>
                  <input
                    id='license_details'
                    name='license_details'
                    type='text'
                    defaultValue={
                      application.license_details
                        ? application.license_details
                        : ""
                    }
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Specialty */}
              <div>
                <label
                  htmlFor='specialty'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Specialty *
                </label>
                <div className='mt-2'>
                  <input
                    id='specialty'
                    name='specialty'
                    type='text'
                    defaultValue={
                      application.specialty ? application.specialty : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Notice Period */}
              <div>
                <label
                  htmlFor='notice_period'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Notice Period <span className='font-light'>(days)</span> *
                </label>
                <div className='mt-2'>
                  <input
                    id='notice_period'
                    name='notice_period'
                    type='text'
                    defaultValue={
                      application.notice_period ? application.notice_period : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          {/* Form Stepper Buttons */}
          <div className='flex flex-row justify-around w-full mt-8 px-10'>
            <button
              className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
              onClick={() => navigate("/dashboard/application/edit/languages")}
            >
              Previous
            </button>

            <button
              className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
              onClick={handleNextButtonClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default WorkInfo;

import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Stepper from "../components/stepper";
import { LanguageListItem } from "../../../management/table/components/application-modal";
import axios from "axios";

const LanguageInfo = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();

  const handleLanguageSubmit = async (event) => {
    event.preventDefault();
    const languageData = {
      language: event.target.language.value,
      read: event.target.read.value,
      speak: event.target.speak.value,
      listen: event.target.listen.value,
      toefl: event.target.toefl.value,
    };

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}/insertLanguage`,
        languageData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // Atualizar os dados do aplicante após a inserção do idioma
      const updatedApplication = await axios.get(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      updateApplication(updatedApplication.data);
    } catch (error) {
      console.error("Error inserting language:", error.message);
    }
  };

  return (
    <motion.div
      className='flex flex-col w-full h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={3} />
      <hr className='border-zinc-900 my-5' />
      <div className='flex flex-col h-full justify-between px-12'>
        <div className='px-10 flex flex-col'>
          <h1 className='text-xl font-bold mt-10'>Add your Languages</h1>
          <hr className='border-zinc-900 w-full mb-5' />

          <form onSubmit={handleLanguageSubmit}>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
              <div className=''>
                <label
                  htmlFor='language'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Language Name
                </label>
                <input
                  id='language'
                  name='language'
                  type='text'
                  autoComplete='language'
                  required
                  className='block w-full rounded-md mt-2 border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                />
              </div>
              <div className=''>
                <label
                  htmlFor='read'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Reading Level
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='read'
                    name='read'
                    required
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={4}>Professional</option>
                    <option value={3}>Advanced</option>
                    <option value={2}>Intermediate</option>
                    <option selected value={1}>
                      Beginner
                    </option>
                  </select>
                </div>
              </div>
              <div className=''>
                <label
                  htmlFor='speak'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Speaking Level
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='speak'
                    name='speak'
                    required
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={4}>Professional</option>
                    <option value={3}>Advanced</option>
                    <option value={2}>Intermediate</option>
                    <option selected value={1}>
                      Beginner
                    </option>
                  </select>
                </div>
              </div>
              <div className=''>
                <label
                  htmlFor='listen'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Listening Level
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='listen'
                    name='listen'
                    required
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={4}>Professional</option>
                    <option value={3}>Advanced</option>
                    <option value={2}>Intermediate</option>
                    <option selected value={1}>
                      Beginner
                    </option>
                  </select>
                </div>
              </div>
              <div className=''>
                <label
                  htmlFor='toefl'
                  className='block text-sm font-medium leading-6 text-zinc-900 font-semibold'
                >
                  Toefl Score
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <input
                    id='toefl'
                    name='toefl'
                    type='number'
                    placeholder="Leave blank if you don't have an associated language exam score"
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center mt-10'>
              <button
                className='bg-zinc-900 hover:bg-zinc-500 text-white font-semibold p-3 px-4 rounded-full'
                type='submit'
              >
                Add Language
              </button>
            </div>
          </form>

          <div className=' mt-10 mb-3'>
            <span className='font-semibold'>Languages:</span>
            <div className='grid grid-cols-4 gap-6 p-6 h-full w-full min-h-36 border border-zinc-900 rounded-lg mt-2'>
              {application.languages &&
                application.languages.map((language) => {
                  return (
                    <LanguageListItem key={language.id} language={language} />
                  );
                })}
            </div>
          </div>
        </div>

        {/* Form Stepper Buttons */}
        <div className='flex flex-row justify-around w-full mt-8 px-10'>
          <button
            className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
            onClick={() => navigate("/dashboard/application/edit/education")}
          >
            Previous
          </button>

          <button
            className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
            onClick={() => navigate("/dashboard/application/edit/work")}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default LanguageInfo;

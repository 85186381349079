const RecruiterCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }

  return (
    <>
      <div className='w-full flex justify-center mr-8'>
        <span
          className={`px-4 py-1 rounded-full text-white text-sm font-semibold ${info === "FAS" ? "bg-red-500" : "bg-zinc-500"}`}
        >
          {info}
        </span>
      </div>
    </>
  );
};

export default RecruiterCell;

import {
  ChatBubbleLeftEllipsisIcon,
  CheckBadgeIcon,
  DocumentCheckIcon,
  GlobeAmericasIcon,
  PencilIcon,
  CheckCircleIcon,
  BriefcaseIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { useDropzone } from "react-dropzone";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../../../misc/loading";

const steps = [
  "Applied",
  "Prequalified",
  "CGFNS",
  "NCLEX",
  "Visa Cred",
  "Job Search",
  "Work Visa",
  "Completed",
];

const UserStatus = () => {
  const [application, setApplication] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api-fly.flyawaystudents.com/api/applicants/${localStorage.getItem("userId")}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setApplication(response.data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, []);

  console.log(application);

  let { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const clearCurrentDocument = () => {
    acceptedFiles = [];
    setApplication({ ...application, cv: "" }); //CHANGE
  };

  const handleCVUpload = async () => {
    const formData = new FormData();
    console.log(acceptedFiles[0]);
    formData.append("cv", acceptedFiles[0]);
    console.log(formData);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      await axios.post(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}/sendFile`,
        formData,
        config,
      );

      console.log("Document Upload Successful");
      //DO SOMETHING
    } catch (error) {
      console.error("Error uploading CV:", error.message);
      setError(error.message);
    }
  };

  if (!application) {
    return <Loading />;
  }

  return (
    <div className='flex h-full w-full bg-gray-100 p-10'>
      <div className=' h-full w-full bg-white rounded-xl shadow-lg p-10'>
        <h1 className='text-4xl font-semibold'>Application Status</h1>
        <hr className='border-zinc-900 my-5' />
        <div className='flex items-start max-w-screen-lg mx-auto mt-12'>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 1 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <DocumentCheckIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 1 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Pre-Qual</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 1 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 2 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <ChatBubbleLeftEllipsisIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 2 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Language</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 2 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 3 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <CheckBadgeIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 3 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>CGFNS</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 3 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 4 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <PencilIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 4 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>NCLEX</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 4 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 5 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <GlobeAmericasIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 5 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Visa Cred</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 5 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 6 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <DocumentMagnifyingGlassIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 6 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Job Search</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 6 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <div
                className={`${application.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 7 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <BriefcaseIcon width={22} />
                </span>
              </div>
              <div
                className={`${application.status - 1 > 7 ? "bg-red-600" : "bg-zinc-400"} w-full h-1 mx-4 rounded-lg`}
              ></div>
            </div>
            <div className='mt-2 mr-4'>
              <h6 className='text-base font-bold text-zinc-900'>Work Visa</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 > 7 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
          <div>
            <div className='flex items-center'>
              <div
                className={`${application.status - 1 == 8 ? "bg-red-600" : "bg-zinc-400"} w-8 h-8 shrink-0 mx-[-1px] p-5 flex items-center justify-center rounded-full ${application.status - 1 == 8 ? "ring-2 ring-red-600" : ""}`}
              >
                <span className='text-base text-white font-bold'>
                  <CheckCircleIcon width={22} />
                </span>
              </div>
            </div>
            <div className='mt-2'>
              <h6 className='text-base font-bold text-zinc-900'>Completion</h6>
              <p className='text-xs text-gray-500'>
                {application.status - 1 == 8 ? "Completed" : "Pending"}
              </p>
            </div>
          </div>
        </div>
        <div className='flex flex-col w-full mt-12 shadow-lg items-center'>
          <h1 className='text-3xl'>
            Current Step: {steps[application.status - 1]}
          </h1>
          <div className='w-full h-full mt-5'>
            {/* DropZone */}
            <form>
              <div className='flex flex-col items-center'>
                <div className='flex w-full lg:w-3/4 justify-center shadow-lg items-center text-lg text-center p-10 mt-5 border-2 border-dashed border-zinc-900 rounded-lg hover:bg-gray-300 hover:cursor-pointer hover:font-semibold transition-all'>
                  <section className='container'>
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} required type='file' />
                      <p>
                        Drag and drop your documents here, or click to select
                        your file
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </form>

            <div className='mt-5 p-2 flex flex-col items-center'>
              <label className='mt-4 px-3 font-semibold' htmlFor='uploads'>
                Uploaded Files
              </label>
              <div
                id='uploads'
                className='grid grid-cols-1 gap-3 rounded-lg p-6 mt-2 bg-zinc-200 shadow-lg w-1/2'
              >
                <div
                  className={`${acceptedFiles.length > 0 ? "visible" : "hidden"} flex flex-col items-center rounded-lg shadow-md p-5 bg-white`}
                >
                  <h4 className='font-bold text-lg mb-4'>
                    Uploaded Documents:
                  </h4>
                  <div className='flex flex-col gap-4 list-none items-center'>
                    {files}
                  </div>
                </div>
              </div>
              <div className='w-1/2 flex flex-row gap-8 justify-center items-center py-10'>
                <button
                  className='bg-gray-600 shadow-lg hover:scale-105 text-white rounded-full p-3 font-semibold text-lg hover:brightness-75 hover:cursor-pointer transition-all'
                  onClick={() => clearCurrentDocument()}
                >
                  Clear Documents
                </button>
                <button
                  className='bg-red-600 shadow-lg hover:scale-105 text-white rounded-full p-3 font-semibold text-lg hover:brightness-75 hover:cursor-pointer transition-all'
                  onClick={() => handleCVUpload()}
                >
                  Submit Step Documents
                </button>
              </div>
              {error && (
                <div className='flex w-full text-red-500 font-light text-2xl'>
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserStatus;

/* eslint-disable react/no-unescaped-entities */
import NavBar from "../components/navigation/nav-bar";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Footer from "../components/misc/footer";

const HowItWorks = () => {
  const location = useLocation();
  const hashProcessed = useRef(false);
  // Content sections
  const sections = [
    {
      id: "step1",
      title: "US Education Requirements",
      content: (
        <section className='py-6 px-1'>
          <p>
            To begin, internationally educated nurses must meet basic education
            requirements, including graduation from an accredited Registered
            Nursing program (ADN or BSN), licensing as a Registered Nurse (RN),
            and at least two years of experience practicing as an RN.
          </p>
          <h4 className='mt-6 mb-4 font-semibold'>Documents Needed:</h4>
          <ul className='list-decimal pl-6'>
            <li className='my-2'>
              Graduation certificate from an accredited Registered Nursing
              program
            </li>
            <li className='my-2'>RN license</li>
            <li className='my-2'>
              Proof of at least two years of RN experience
            </li>
          </ul>
        </section>
      ),
    },
    {
      id: "step2",
      title: "English Language Proficiency",
      content: (
        <section className='py-6 px-1'>
          <p>
            Following the education requirements, some foreign-educated nurses
            must take and pass English language proficiency tests such as the
            TOEFL, TOEIC, or IELTS, depending on their country of origin.
          </p>
          <h4 className='mt-6 mb-4 font-semibold'>Documents Needed:</h4>
          <ul className='list-decimal pl-6'>
            <li className='my-2'>TOEFL, TOEIC, or IELTS score report</li>
          </ul>
        </section>
      ),
    },
    {
      id: "step3",
      title: "NCLEX-RN Examination",
      content: (
        <section className='py-6 px-1'>
          <p>
            After meeting education and language requirements, applicants can
            apply for the NCLEX-RN Examination in the state of intended
            employment. Registration with Pearson Vue is required, along with a
            fee. This step determines if foreign-educated nurses are well-suited
            to care for patients in the USA.
          </p>
          <h4 className='mt-6 mb-4 font-semibold'>Documents Needed:</h4>
          <ul className='list-decimal pl-6'>
            <li className='my-2'>NCLEX-RN Examination application</li>
            <li className='my-2'>Registration fee</li>
          </ul>
        </section>
      ),
    },
    {
      id: "step4",
      title: "CGFNS Credential Evaluation",
      content: (
        <section className='py-6 px-1'>
          <p>
            Applicants must have their credentials evaluated by a credentialing
            organization such as the Commission on Graduates of Foreign Nursing
            Schools (CGFNS). This evaluation involves reviewing nursing school
            transcripts and education history from the applicant's home country.
            The CGFNS Certification Program, requiring the CGFNS Qualifying
            Exam, is also an option.
          </p>
          <h4 className='mt-6 mb-4 font-semibold'>Documents Needed:</h4>
          <ul className='list-decimal pl-6'>
            <li className='my-2'>Nursing school transcripts</li>
            <li className='my-2'>Education history</li>
            <li className='my-2'>CGFNS evaluation application</li>
            <li className='my-2'>
              CGFNS Qualifying Exam results (if applicable)
            </li>
          </ul>
        </section>
      ),
    },
    {
      id: "step5",
      title: "Connecting you to a hospital",
      content: (
        <section className='py-6 px-1'>
          <p>
            The next step is to find a nursing recruiting agency or US-based
            employer. FAS, for instance, offers comprehensive support in
            obtaining an employment visa. Working with an independent staffing
            recruiter like FAS may make the process easier than working directly
            with a hospital.
          </p>
        </section>
      ),
    },
    {
      id: "step6",
      title: "Employment Visa",
      content: (
        <section className='py-6 px-1'>
          <p>
            Finally, applicants can obtain their nursing employment visa or
            "work visa," such as the H-1B Temporary Work Visa or the EB-3 Visa,
            depending on their qualifications and job offers. These visas allow
            nurses to work in the USA for a specified period, with options to
            apply for a green card or permanent residency.
          </p>
          <h4 className='mt-6 mb-4 font-semibold'>Documents Needed:</h4>
          <ul className='list-decimal pl-6'>
            <li className='my-2'>Visa application</li>
            <li className='my-2'>Employment offer letter</li>
            <li className='my-2'>Sponsorship documents</li>
            <li className='my-2'>Proof of qualifications</li>
          </ul>

          <p className='pt-10 font-semibold'>
            This process, while complex, is made more manageable with the
            guidance and support of Fly Away Students, where we specialize in
            helping internationally educated nurses transition to working in the
            USA healthcare system.
          </p>
        </section>
      ),
    },
  ];

  // State to track the currently active section
  const [activeSection, setActiveSection] = useState("");

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollPosition = window.scrollY + 300; // Add the offset here
    let newActiveSection = "";

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        const { offsetTop, offsetHeight } = sectionElement;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          newActiveSection = section.id;
        }
      }
    });

    if (
      newActiveSection !== activeSection &&
      newActiveSection !== null &&
      newActiveSection !== ""
    ) {
      setActiveSection(newActiveSection);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  useEffect(() => {
    if (!hashProcessed.current) {
      // Get the hash from the URL
      const hash = location.hash;
      if (hash) {
        // Remove the '#' character
        const sectionId = hash.substring(1);
        // Check if the section exists in your sections array
        if (sections.some((section) => section.id === sectionId)) {
          scrollToSection(sectionId);
          setActiveSection(sectionId);
        }
      }
      hashProcessed.current = true;
    }
  }, [location, sections]);

  // Function to scroll to a section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - 300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <div className='bg-gray-100'>
      <div className='flex flex-col'>
        <div className='z-30'>
          <NavBar />
        </div>
        <motion.div
          className='h-full bg-zinc-50 mt-20 flex'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          exitBeforeEnter={true}
        >
          <div className='relative w-full'>
            <div className='flex bg-gray-100'>
              <div className='w-0 md:w-1/4'>
                <div className='fixed invisible md:visible p-16 py-20 left-0 top-0 h-full overflow-y-auto mt-20'>
                  <h1 className='text-3xl'>Table of Contents:</h1>
                  <ul className='pl-6'>
                    {sections.map((section) => (
                      <li key={section.id} className='my-5'>
                        <a
                          href={`#${section.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(section.id);
                          }}
                          className={
                            activeSection === section.id
                              ? "font-bold text-lg drop-shadow-lg"
                              : ""
                          }
                        >
                          {section.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-full md:w-3/5 p-20 pl-1/4 pt-36'>
                <h1 className='text-4xl font-bold'>How It Works</h1>
                {sections.map((section) => (
                  <div
                    key={section.id}
                    id={section.id}
                    className='my-16 text-lg'
                  >
                    <h2 className='text-2xl font-semibold'>{section.title}</h2>
                    <p>{section.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        <Footer />
      </div>
    </div>
  );
};

export default HowItWorks;

import { useEffect } from "react";

import NavBar from "../components/navigation/nav-bar";
import RegisterForm from "../components/auth/register-form";

export default function Register() {
  useEffect(() => {
    document.title = "FAS Register";
  }, []);

  return (
    <div className='flex flex-col h-full w-auto'>
      <NavBar />
      <RegisterForm />
    </div>
  );
}

const LanguageCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }
  return (
    <>
      <div className='flex flex-row flex-wrap gap-2 justify-center items-center w-full py-2'>
        {info.map((language) => {
          const languageLevel =
            language.speak + language.read + language.listen;
          return (
            <span
              key={language.language}
              className={`px-4 py-1 my-1 rounded-full text-white text-sm font-semibold ${languageLevel <= 4 ? "bg-red-500" : languageLevel <= 7 ? "bg-orange-500" : languageLevel <= 10 ? "bg-green-500" : languageLevel > 10 ? "bg-yellow-500" : "bg-gray-500"}`}
            >
              {language.language}
            </span>
          );
        })}
      </div>
    </>
  );
};

export default LanguageCell;

import NavBar from "../components/navigation/nav-bar";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Accordion from "../components/misc/accordion";
import Footer from "../components/misc/footer";

const FAQ = () => {
  useEffect(() => {
    document.title = "FAS FAQ";
  }, []);

  return (
    <div className='bg-gray-100'>
      <NavBar />
      <motion.div
        className='h-full w-full flex flex-col justify-center items-center mt-20 overflow-y-auto bg-gray-100'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className=''>
          <Accordion />
        </div>

        <div className=''>
          <Footer />
        </div>
      </motion.div>
    </div>
  );
};

export default FAQ;

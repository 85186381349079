import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import "./index.css";

import Landing from "./routes/landing";
import Login from "./routes/login";
import AboutUs from "./routes/aboutus";
import Contact from "./routes/contact";
import HowItWorks from "./routes/howitworks";
import FAQ from "./routes/faq";
import ManagementDashboard from "./routes/management-dashboard";
import UserDashboard from "./routes/user-dashboard";
import Register from "./routes/register";
import LoginAdmin from "./routes/login-management";

import ErrorPage from "./error-page";
import TableContainer from "./components/management/table-container";
import AllApplicantsTable from "./components/management/table/all-applicants";
import UnqualifiedApplicantsTable from "./components/management/table/unqualified-applicants";
import InProgressApplicantsTable from "./components/management/table/in-progress-applicants";
import WaitingForReviewApplicantsTable from "./components/management/table/waiting-for-review-applicants";
import CompletedApplicantsTable from "./components/management/table/completed-applicants";
import ManagementSettings from "./components/management/management-settings";
import ApplicationModal from "./components/management/table/components/application-modal";
import UserForm from "./components/user/form/components/user-form";
import UserStatus from "./components/user/form/components/user-status";
import PersonalInfo from "./components/user/form/steps/personal-info";
import WorkInfo from "./components/user/form/steps/work-info";
import ImmigrationInfo from "./components/user/form/steps/immigration-info";
import EducationInfo from "./components/user/form/steps/education-info";
import CVUpload from "./components/user/form/steps/cv-upload";
import AdditionalInfo from "./components/user/form/steps/additional-info";
import ReviewInfo from "./components/user/form/steps/review-info";
import ViewApplication from "./components/user/form/components/view-application";
import LanguageInfo from "./components/user/form/steps/languages-info";
import ForgotForm from "./components/auth/forgot";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/howitworks",
    element: <HowItWorks />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/faq",
    element: <FAQ />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/admin/login",
    element: <LoginAdmin />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot",
    element: <ForgotForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <Register />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/management",
    element: <ManagementDashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/management/applications",
        element: <TableContainer />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/management/applications/all",
            element: <AllApplicantsTable />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/management/applications/unqualified",
            element: <UnqualifiedApplicantsTable />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/management/applications/inprogress",
            element: <InProgressApplicantsTable />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/management/applications/waitingforreview",
            element: <WaitingForReviewApplicantsTable />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/management/applications/completed",
            element: <CompletedApplicantsTable />,
            errorElement: <ErrorPage />,
          },
          {
            path: "/management/applications/:id",
            element: <ApplicationModal />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: "/management/settings",
        element: <ManagementSettings />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/dashboard",
    element: <UserDashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/dashboard/application",
        element: <ViewApplication />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/dashboard/application/edit",
            element: <UserForm />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: "/dashboard/application/edit/personal",
                element: <PersonalInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/cv",
                element: <CVUpload />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/education",
                element: <EducationInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/languages",
                element: <LanguageInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/work",
                element: <WorkInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/immigration",
                element: <ImmigrationInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/additional",
                element: <AdditionalInfo />,
                errorElement: <ErrorPage />,
              },
              {
                path: "/dashboard/application/edit/review",
                element: <ReviewInfo />,
                errorElement: <ErrorPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/dashboard/applicationstatus",
        element: <UserStatus />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <div className='h-screen bg-gray-100'>
      <AnimatePresence>
        <RouterProvider router={router} />
      </AnimatePresence>
    </div>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useOutletContext, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Stepper from "../components/stepper";
import axios from "axios";

const ImmigrationInfo = () => {
  let [application, updateApplication] = useOutletContext();

  const navigate = useNavigate();

  const submitApplicationChange = async () => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `https://api-fly.flyawaystudents.com/api/applicants/${application.id}`,
        application,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/dashboard/application/edit/additional");
    } catch (error) {
      console.error("Error updating application:", error.message);
    }
  };

  const handleApplicationChange = (event) => {
    updateApplication({
      ...application,
      [event.target.name]: event.target.value,
    });
  };

  const handleNextButtonClick = async () => {
    await submitApplicationChange();
  };

  return (
    <motion.div
      className='flex flex-col w-full h-full'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Stepper currIndex={5} />
      <hr className='border-zinc-900 w-full' />
      <div className='w-full h-full px-10 flex flex-col justify-between'>
        <div>
          <form className='w-full'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-10 mt-10 w-full'>
              {/* Immigration Status */}
              <div>
                <label
                  htmlFor='immigration_status'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Immigration Status *
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='immigration_status'
                    onChange={handleApplicationChange}
                    name='immigration_status'
                    defaultValue={
                      application.immigration_status
                        ? application.immigration_status
                        : ""
                    }
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={0}>Citizen</option>
                    <option value={1}>Greencard</option>
                    <option value={2}>Foreign</option>
                    <option value={3}>Other</option>
                  </select>
                </div>
              </div>

              {/* Visa Status */}
              <div>
                <label
                  htmlFor='visa_status'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Visa Status *
                </label>
                <div className='mt-2 flex flex-col gap-3 items-start'>
                  <select
                    id='visa_status'
                    onChange={handleApplicationChange}
                    name='visa_status'
                    defaultValue={
                      application.visa_status ? application.visa_status : ""
                    }
                    className='block w-full rounded-md border-0 p-3 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  >
                    <option value={0}>J-1 Visa</option>
                    <option value={1}>H1-B Visa</option>
                    <option value={2}>EB-3 Visa</option>
                    <option value={3}>Other Visa</option>
                    <option value={4}>No Visa</option>
                  </select>
                </div>
              </div>

              {/* Visa Details */}
              <div>
                <label
                  htmlFor='visa_details'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Visa Details *
                </label>
                <div className='mt-2'>
                  <input
                    id='visa_details'
                    name='visa_details'
                    type='text'
                    defaultValue={
                      application.visa_details ? application.visa_details : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Nationality */}
              <div>
                <label
                  htmlFor='nationality'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Nationality
                </label>
                <div className='mt-2'>
                  <div className='mt-2'>
                    <input
                      id='nationality'
                      name='nationality'
                      type='text'
                      onChange={handleApplicationChange}
                      defaultValue={
                        application.nationality ? application.nationality : ""
                      }
                      className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                    />
                  </div>
                </div>
              </div>

              {/* Marital Status */}
              <div>
                <label
                  htmlFor='marital_status'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Marital Status *
                </label>
                <div className='mt-2'>
                  <input
                    id='marital_status'
                    name='marital_status'
                    type='text'
                    defaultValue={
                      application.marital_status
                        ? application.marital_status
                        : ""
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Number of Children */}
              <div>
                <label
                  htmlFor='number_children'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Number of Children *
                </label>
                <div className='mt-2'>
                  <input
                    id='number_children'
                    name='number_children'
                    type='number'
                    defaultValue={
                      application.number_children
                        ? application.number_children
                        : 0
                    }
                    required
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>

              {/* Driver's License Country */}
              <div>
                <label
                  htmlFor='dl_country'
                  className='block text-sm font-medium leading-6 text-zinc-900'
                >
                  Driver License Issuing Country
                </label>
                <div className='mt-2'>
                  <input
                    id='dl_country'
                    name='dl_country'
                    type='text'
                    defaultValue={
                      application.dl_country ? application.dl_country : ""
                    }
                    required
                    autoComplete='country-name'
                    onChange={handleApplicationChange}
                    className='block w-full rounded-md border-0 p-1.5 px-3 text-zinc-900 shadow-lg ring-1 ring-inset ring-zinc-500 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          {/* Form Stepper Buttons */}
          <div className='flex flex-row justify-around w-full mt-8 px-10'>
            <button
              className='bg-zinc-700 shadow-md rounded-full p-3 px-4 text-gray-100 font-semibold hover:bg-zinc-600 transition-all'
              onClick={() => navigate("/dashboard/application/edit/work")}
            >
              Previous
            </button>

            <button
              className='bg-red-600 shadow-md rounded-full p-3 px-4 text-white font-semibold hover:bg-red-500 transition-all'
              onClick={handleNextButtonClick}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ImmigrationInfo;

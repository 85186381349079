import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import { rankItem, compareItems } from "@tanstack/match-sorter-utils";
import { useReducer, useMemo, useState, useEffect, useRef } from "react";
import TableCell from "./components/table-cell";
import CountryCell from "./components/country-cell";
import BooleanCell from "./components/boolean-cell";
import RecruiterCell from "./components/recruiter-cell";
import YoeCell from "./components/yoe-cell";
import VisaCell from "./components/visa-cell";
import LanguageCell from "./components/language-cell";
import ProgressCell from "./components/progress-cell";
import DegreeCell from "./components/degree-cell";
import VisaStatusCell from "./components/visa-status-cell";
import ResumeCell from "./components/resume-cell";
import ProfessionCell from "./components/profession-cell";
import IdCell from "./components/id-cell";
import { fetchCompletedApplicants } from "../applications/applicationService";
import { Link } from "react-router-dom";
import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  FunnelIcon,
} from "@heroicons/react/24/outline";

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank,
      rowB.columnFiltersMeta[columnId]?.itemRank,
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

const CompletedApplicantsTable = () => {
  const rerender = useReducer(() => ({}), {})[1];

  const [columnFilters, setColumnFilters] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [currentOpenFilter, setCurrentOpenFilter] = useState("");

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event?.target)) {
          setCurrentOpenFilter("");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const filterWrapperRef = useRef(null);
  useOutsideAlerter(filterWrapperRef);

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className='w-full flex justify-center px-1'>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor("id", {
        header: () => <div className='whitespace-nowrap my-3 px-2'>ID</div>,
        id: "id",
        cell: (info) => <IdCell info={info.getValue()} />,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      }),
      columnHelper.accessor("name", {
        header: () => <div className='whitespace-nowrap my-3 px-2'>Name</div>,
        id: "name",
        cell: (info) => <TableCell info={info.getValue()} />,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      }),
      columnHelper.accessor("country", {
        header: () => (
          <div className='whitespace-nowrap my-3 px-2'>Current Country</div>
        ),
        id: "country",
        cell: (info) => <CountryCell info={info.getValue()} />,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      }),
      columnHelper.accessor("email", {
        header: () => <div className='whitespace-nowrap my-3 px-2'>Email</div>,
        id: "email",
        cell: (info) => <TableCell info={info.getValue()} />,
        filterFn: "fuzzy",
        sortingFn: fuzzySort,
      }),
      columnHelper.accessor("phone_number", {
        header: () => <div className='whitespace-nowrap my-3 px-2'>Phone</div>,
        id: "phone",
        cell: (info) => <TableCell info={info.getValue()} />,
      }),
      columnHelper.accessor("recruiter", {
        header: () => (
          <div className='whitespace-nowrap my-3 px-2'>Recruiter</div>
        ),
        id: "recruiter",
        cell: (info) => <RecruiterCell info={info.getValue()} />,
      }),
      columnHelper.accessor("nationality", {
        header: () => (
          <div className='whitespace-nowrap my-3 px-2'>Nationality</div>
        ),
        id: "nationality",
        cell: (info) => <CountryCell info={info.getValue()} />,
      }),
      columnHelper.accessor("date_of_birth", {
        header: () => <div className='whitespace-nowrap my-3 px-2'>DOB</div>,
        id: "date_of_birth",
        cell: (info) => <TableCell info={info.getValue()} />,
      }),
      columnHelper.accessor("languages", {
        header: () => (
          <div className='whitespace-nowrap my-3 px-2'>Languages</div>
        ),
        id: "languages",
        cell: (info) => <LanguageCell info={info.getValue()} />,
      }),
      columnHelper.accessor("position_id", {
        header: () => <div className='whitespace-nowrap my-3'>Profession</div>,
        id: "profession",
        cell: (info) => <ProfessionCell info={info.getValue()} />,
      }),
      columnHelper.accessor("specialty", {
        header: () => <div className='whitespace-nowrap my-3'>Specialty</div>,
        id: "specialty",
        cell: (info) => <TableCell info={info.getValue()} />,
      }),
      columnHelper.accessor("total_yoe", {
        header: () => <div className='whitespace-nowrap my-3'>YOE</div>,
        id: "total_yoe",
        cell: (info) => <YoeCell info={info.getValue()} />,
      }),
      columnHelper.accessor("school", {
        header: () => <div className='whitespace-nowrap my-3'>College</div>,
        id: "college",
        cell: (info) => <TableCell info={info.getValue()} />,
      }),
      columnHelper.accessor("highest_educational_certificate", {
        header: () => <div className='whitespace-nowrap my-3'>Degree</div>,
        id: "degree",
        cell: (info) => <DegreeCell info={info.getValue()} />,
      }),
      columnHelper.accessor("immigration_status", {
        header: () => <div className='whitespace-nowrap my-3'>Status</div>,
        id: "status",
        cell: (info) => <VisaStatusCell info={info.getValue()} />,
      }),
      columnHelper.accessor("visa_status", {
        header: () => <div className='whitespace-nowrap my-3'>Visa</div>,
        id: "visa",
        cell: (info) => <VisaCell info={info.getValue()} />,
      }),
      columnHelper.accessor("work_permit", {
        header: () => <div className='whitespace-nowrap my-3'>Work Permit</div>,
        id: "workPermit",
        cell: (info) => <BooleanCell info={info.getValue()} />,
      }),
      columnHelper.accessor("status", {
        header: () => <div className='whitespace-nowrap my-3'>Progress</div>,
        id: "progress",
        cell: (info) => <ProgressCell info={info.getValue()} />,
      }),
      columnHelper.display({
        header: () => <div className='whitespace-nowrap my-3'>Resume</div>,
        id: "cv",
        cell: (info) => <ResumeCell info={info.getValue()} />,
      }),
    ],
    [],
  );

  const [data, setData] = useState([]);
  const refreshData = () => setData(() => fetchCompletedApplicants());

  useEffect(() => {
    const fetchData = async () => {
      const applicants = await fetchCompletedApplicants();
      applicants ? setData(applicants) : setData([]);
    };

    fetchData();
  }, []);
  console.log("Data: " + data);

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
      rowSelection,
    },
    enableRowSelection: true,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "fullName") {
      if (table.getState().sorting[0]?.id !== "fullName") {
        table.setSorting([{ id: "fullName", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  const handleFilterOpen = (header) => {
    if (header.id !== currentOpenFilter) {
      setCurrentOpenFilter(header.id);
    } else {
      setCurrentOpenFilter("");
    }
  };

  return (
    <div className='flex flex-col w-full h-full justify-center min-w-[600px]'>
      <div className='mx-4 text-lg font-semibold flex flex-row justify-between items-center'>
        <h1 className='text-xl lg:text-3xl font-bold ml-2'>
          Completed Applications
        </h1>

        <div>
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className='p-1 leading-tight lg:p-2 font-sm lg:font-lg shadow border border-zinc-500 rounded-lg'
            placeholder='Global Search'
          />
        </div>
      </div>

      <div className='grow mt-4 max-w-full max-h-full shadow-inner border-2 border-zinc-900 overflow-x-auto bg-white'>
        <table className='table-auto'>
          <thead className='sticky top-0 left-0 bg-zinc-900 text-white z-10 shadow-md border-zinc-900'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className='min-h-[30px]'>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`px-4 lg:px-9 my-2 ${header.id == "select" || header.id == "resume" ? "" : "hover:bg-zinc-500"} hover:shadow-inner transition-all`}
                    >
                      {header.isPlaceholder ? null : (
                        <div className='w-full flex flex-row justify-between mx-2'>
                          <div
                            {...{
                              className: `${
                                header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : ""
                              }`,
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            <div className='flex flex-row items-center'>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                              {{
                                asc: (
                                  <BarsArrowUpIcon
                                    width={20}
                                    className='ml-2'
                                  />
                                ),
                                desc: (
                                  <BarsArrowDownIcon
                                    width={20}
                                    className='ml-2'
                                  />
                                ),
                              }[header.column.getIsSorted()] ?? null}
                            </div>
                          </div>
                          {header.column.getCanFilter() ? (
                            <div className='flex ml-6 mr-4'>
                              <button
                                className='text-zinc-200 hover:text-white transition-all'
                                type='button'
                                onClick={() => handleFilterOpen(header)}
                              >
                                <FunnelIcon width={20} />
                              </button>
                              {currentOpenFilter == header.id && (
                                <div
                                  ref={filterWrapperRef}
                                  className='absolute top-10 flex flex-col py-5 rounded-2xl justify-center bg-zinc-50 shadow-md border border-zinc-900 h-auto w-56 z-30 transition-all'
                                >
                                  <h5 className='text-black mb-4 text-lg'>
                                    Filter{" "}
                                    {header.id == "id"
                                      ? "ID"
                                      : header.id[0].toUpperCase() +
                                        header.id.slice(1)}
                                  </h5>
                                  <div className='w-full flex justify-center'>
                                    <Filter
                                      column={header.column}
                                      table={table}
                                    />
                                  </div>
                                  <div>
                                    <button className='transition-all text-white bg-zinc-900 mt-4 w-1/2 rounded-2xl'>
                                      Clear
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className='overflow-y-auto'>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className={`cursor-pointer bg-zinc-50 border-y border-zinc-900 shadow-lg transition transform hover:-translate-y-1 hover:bg-zinc-200 z-50`}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        <Link
                          className='h-full w-full'
                          to={`/management/applications/${row.original.id}`}
                        >
                          <div className='flex justify-start mx-2 font-roboto'>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </div>
                        </Link>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='grow-0'>
        <div className='mb-4 lg:mb-2 xl:mb-0 ml-2 mt-2 text-lg font-semibold'>
          {table.getPrePaginationRowModel()?.rows?.length} Applications in total
        </div>
        <div className='flex justify-center items-center gap-2'>
          <button
            className='bg-zinc-900 text-white rounded-full text-sm lg:text-md p-[14px] cursor-pointer hover:p-3 hover:bg-white hover:text-zinc-900 hover:border-2 hover:border-zinc-900'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className='bg-zinc-900 text-white rounded-full text-sm lg:text-md p-[14px] cursor-pointer hover:p-3 hover:bg-white hover:text-zinc-900 hover:border-2 hover:border-zinc-900'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          <button
            className='bg-zinc-900 text-white rounded-full text-sm lg:text-md p-[14px] cursor-pointer hover:p-3 hover:bg-white hover:text-zinc-900 hover:border-2 hover:border-zinc-900'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className='bg-zinc-900 text-white rounded-full text-sm lg:text-md p-[14px] cursor-pointer hover:p-3 hover:bg-white hover:text-zinc-900 hover:border-2 hover:border-zinc-900'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
          <span className='flex items-center gap-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </span>
          <span className='flex items-center gap-1'>
            | Go to page:
            <input
              type='number'
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                if (e.target.value) {
                  if (Number(e.target.value < 1)) {
                    e.target.value = 1;
                    table.setPageIndex(0);
                  } else if (Number(e.target.value) > table.getPageCount()) {
                    e.target.value = table.getPageCount();
                    table.setPageIndex(table.getPageCount() - 1);
                  } else {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    table.setPageIndex(page);
                  }
                }
              }}
              className='border border-zinc-900 p-1 rounded w-16'
            />
          </span>
          <select
            className='border border-zinc-900 rounded-lg p-1 bg-zinc-900 text-white'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        <div className='flex flex-row justify-center mt-4'>
          <div className='mx-4'>
            <button
              className='rounded-full p-4 bg-zinc-900 text-white hover:bg-white hover:text-zinc-900 hover:border-2 hover:p-[14px] hover:border-zinc-900'
              onClick={() => rerender()}
            >
              Force Rerender
            </button>
          </div>
          <div className='mx-4'>
            <button
              className='rounded-full p-4 bg-zinc-900 text-white hover:bg-white hover:text-zinc-900 hover:border-2 hover:p-[14px] hover:border-zinc-900'
              onClick={() => refreshData()}
            >
              Refresh Data
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedApplicantsTable;

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()],
  );

  return typeof firstValue === "number" ? (
    <div className='flex space-x-2 mb-1'>
      <DebouncedInput
        type='number'
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={columnFilterValue?.[0] ?? ""}
        onChange={(value) => column.setFilterValue((old) => [value, old?.[1]])}
        placeholder={`Min ${
          column.getFacetedMinMaxValues()?.[0]
            ? `(${column.getFacetedMinMaxValues()?.[0]})`
            : ""
        }`}
        className='w-24 border shadow rounded text-black'
      />
      <DebouncedInput
        type='number'
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
        value={columnFilterValue?.[1] ?? ""}
        onChange={(value) => column.setFilterValue((old) => [old?.[0], value])}
        placeholder={`Max ${
          column.getFacetedMinMaxValues()?.[1]
            ? `(${column.getFacetedMinMaxValues()?.[1]})`
            : ""
        }`}
        className='w-24 border shadow rounded text-black'
      />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={columnFilterValue ?? ""}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded mb-1 text-black p-1 px-2'
        list={column.id + "list"}
      />
    </>
  );
}

// A debounced input react component
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className='flex flex-row justify-center items-center hover:cursor-pointer '>
      <input
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

function IndeterminateCheckbox({
  indeterminate,
  className = "h-4 w-4 accent-rose-600 hover:accent-rose-600 bg-gray-100 border-gray-300 rounded focus:ring-red-500",
  ...rest
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type='checkbox'
      ref={ref}
      className={className + " cursor-pointer"}
      {...rest}
    />
  );
}

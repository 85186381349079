const BooleanCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }
  return (
    <>
      {info ? (
        <div className='w-full flex justify-center'>
          <span className='rounded-full bg-green-500 text-white text-sm font-semibold px-4 py-1'>
            True
          </span>
        </div>
      ) : (
        <div className='w-full flex justify-center'>
          <span className='rounded-full bg-red-500 text-white text-sm font-semibold px-4 py-1'>
            False
          </span>
        </div>
      )}
    </>
  );
};

export default BooleanCell;

import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='flex flex-col h-full w-full items-center justify-center'>
      <h1 className='text-8xl my-8 animate-bounce font-bold'>Oops!</h1>
      <p className='mb-5 text-2xl'>Sorry, an unexpected error has occurred.</p>
      <p className='w-3/5 flex justify-center items-center'>
        <i className=''>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

import { useContext, createContext, useState, useEffect, useRef } from "react";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PowerIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";

import { logoutUser } from "../js/Api";

import "../../index.css";

import logo from "../../images/logo-no-bird.png";

const SidebarContext = createContext();

const userName = localStorage.username;

const userInitials = userName
  ? userName.charAt(0) + userName.charAt(userName.lastIndexOf(" ") + 1)
  : "";

const SideBar = ({ children }) => {
  const [expanded, setExpanded] = useState(true);
  const [logOutButton, setLogOutButton] = useState(false);
  const logOutRef = useRef(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (logOutButton && !ref.current.contains(event?.target)) {
          setLogOutButton(!logOutButton);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(logOutRef);

  return (
    <aside className='h-full'>
      <nav className='h-full inline-flex flex-col bg-zinc-900 border-r shadow-lg'>
        <div className='p-4 pb-2 flex justify-between items-center'>
          <a draggable='false' href={`/`}>
            <img
              src={logo}
              className={`overflow-hidden mx-auto hover:brightness-150 transition-all ${
                expanded ? "w-52" : "w-0"
              }`}
              alt=''
            />
          </a>
          <button
            onClick={() => setExpanded((curr) => !curr)}
            className='p-1.5 rounded-lg bg-zinc-700 hover:bg-zinc-500'
          >
            {expanded ? (
              <ChevronDoubleLeftIcon color={"white"} width={20} />
            ) : (
              <ChevronDoubleRightIcon color={"white"} width={20} />
            )}
          </button>
        </div>

        <SidebarContext.Provider value={{ expanded }}>
          <ul className='flex-1 p-3 overflow-x-visible overflow-y-clip'>
            {children}
          </ul>
        </SidebarContext.Provider>

        <div className='border-t flex p-3'>
          {/* Add support to make image lead to profile or possibly just expand profile picture */}
          <a className='hover:cursor-pointer hover:brightness-125'>
            <div className='w-10 h-10 rounded-lg flex justify-center items-center bg-red-600 text-white font-bold text-lg'>
              <span>{userInitials}</span>
            </div>
          </a>
          <div
            className={`
                        flex justify-between items-center
                        overflow-hidden transition-all ${expanded ? "w-52 ml-3 pr-1" : "w-0"}
                    `}
          >
            {/* Add in support for log out modal to appear on Click */}
            <div className='relative inline-flex'>
              <button
                className='rounded-full p-2 hover:bg-zinc-600 hover:cursor-pointer'
                onClick={() => setLogOutButton(!logOutButton)}
              >
                <PowerIcon width={22} color={"white"} />
              </button>
              <button
                ref={logOutRef}
                onClick={handleLogout}
                className={`absolute text-nowrap rounded-full p-2 ml-6 bg-red-600 text-white text-sm font-semibold transition-all overflow-visible ${logOutButton ? "-translate-x-[58px] opacity-100" : "translate-x-10 opacity-20"}`}
              >
                Log Out?
              </button>
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default SideBar;

const handleLogout = async () => {
  try {
    const token = localStorage.getItem("token");

    await logoutUser(token);
    localStorage.removeItem("token");
    window.location.href = "/login";
  } catch (error) {
    console.error("Erro ao fazer logout:", error);
  }
};

export function SidebarItem({ icon, text, active, alert, href }) {
  const { expanded } = useContext(SidebarContext);

  return (
    <a href={href}>
      <li
        className={`
            relative flex text-white items-center py-2 px-3 my-1
            font-medium rounded-md cursor-pointer
            transition-colors group
            ${
              active
                ? "bg-red-600 text-white"
                : "hover:bg-zinc-500 text-gray-600"
            }
        `}
      >
        {icon}
        <span
          className={`overflow-y-hidden transition-all ${
            expanded ? "w-52 ml-3" : "w-0 h-0"
          }`}
        >
          {text}
        </span>
        {alert && (
          <div
            className={`absolute w-2 h-2 right-3 rounded bg-red-400 ${
              expanded ? "" : "top-2"
            }`}
          />
        )}

        {!expanded && (
          <div
            className={`
              absolute text-nowrap left-full rounded-md px-2 py-1 ml-6 z-40
              bg-red-100 text-red-800 text-sm
              invisible opacity-20 -translate-x-3 transition-all
              group-hover:visible group-hover:overflow-visible group-hover:opacity-100 group-hover:translate-x-0
          `}
          >
            {text}
          </div>
        )}
      </li>
    </a>
  );
}

export function NestedSidebarListItem({ headChild, children }) {
  const { expanded } = useContext(SidebarContext);
  const [isOpen, toggleOpen] = useState(false);

  const areChildrenAlert = (childItems) => {
    return childItems.filter((item) => item.alert);
  };

  const handleParentClick = () => {
    toggleOpen(!isOpen);
  };

  return (
    <>
      <a onClick={handleParentClick}>
        <li
          className={`relative flex text-white items-center py-2 px-3 mt-1 font-medium rounded-md cursor-pointer transition-colors group hover:bg-zinc-500 text-gray-600`}
        >
          {expanded ? (
            isOpen ? (
              <ChevronDownIcon color={"white"} width={20} />
            ) : (
              <ChevronRightIcon color={"white"} width={20} />
            )
          ) : (
            <ListBulletIcon color={"white"} width={20} />
          )}
          <span
            className={`overflow-hidden transition-all ${
              expanded ? "w-52 ml-3" : "w-0"
            }`}
          >
            {headChild.text}
          </span>
          <span className='h-full'>
            {alert && !isOpen && areChildrenAlert(children) && (
              <div
                className={`absolute top-0 bottom-0 my-auto right-2 w-2 h-2 rounded bg-red-400 ${
                  expanded ? "" : "top-2"
                }`}
              />
            )}
          </span>

          {!expanded && (
            <div
              className={`
                absolute text-balance left-full rounded-md px-2 py-1 ml-6 z-40
                bg-red-100 text-red-800 text-sm
                invisible opacity-20 -translate-x-3 transition-all
                group-hover:visible group-hover:opacity-100 group-hover:translate-x-0
            `}
            >
              {headChild.text}
            </div>
          )}
        </li>
      </a>

      <ul
        className={`
        flex flex-col justify-between items-center bg-zinc-600 rounded-md transition-all ${isOpen ? "my-2 h-100" : "h-0 opacity-0 overflow-y-hidden"}
        `}
      >
        {children}
      </ul>
    </>
  );
}

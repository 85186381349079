const IdCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }
  return (
    <div className='w-full flex justify-center'>
      <div>
        <span className='pr-6'>{info}</span>
      </div>
    </div>
  );
};

export default IdCell;

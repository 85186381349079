/* eslint-disable react/no-unescaped-entities */
import NavBar from "../components/navigation/nav-bar";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Footer from "../components/misc/footer";
import ContactForm from "../components/misc/contact-form";

const Contact = () => {
  const location = useLocation();
  const hashProcessed = useRef(false);
  // Content sections
  const sections = [
    {
      id: "message",
      title: "Send us a Message",
      content: (
        <section className='py-6 px-1'>
          <ContactForm />
        </section>
      ),
    },
    {
      id: "generalbranch",
      title: "General Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name: <span className='font-light'>Richard Blair</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>
                richard.blair@flyawaystudents.com
              </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+1 (857) 205-2672</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "usbranch",
      title: "US Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name:{" "}
              <span className='font-light'>Filomena Shuman</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>
                filomena.shuman@flyawaystudents.com
              </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+1 (617) 372-7492</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "europebranch",
      title: "Europe Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name:{" "}
              <span className='font-light'>Sara Popov</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>
                sara.popov@flyawaystudents.com
              </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+381 (62) 418 738</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "latinbranch",
      title: "Latin American Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name: <span className='font-light'>Lucila Blair</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>
                lucila.blair@flyawaystudents.com
              </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+55 11 95571-6581</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "asiabranch",
      title: "Asia Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name: <span className='font-light'>Sudip KC</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>sudip.kc@flyawaystudents.com</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+1 (617) 784-6413</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "africabranch",
      title: "Africa Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name:{" "}
              <span className='font-light'>Sylvester Wallace</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email:{" "}
              <span className='font-light'>
                sylvester.wallace@flyawaystudents.com
              </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number:{" "}
              <span className='font-light'>+1 (781) 535-1884</span>
            </div>
          </div>
        </section>
      ),
    },
    {
      id: "capeverdebranch",
      title: "Cape Verde Branch",
      content: (
        <section className='py-6 px-1'>
          <div className='flex flex-col'>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Recruiter Name: <span className='font-light'>Simão Aqueleu </span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Email: <span className='font-light'>simao.aqueleu@flyawaystudents.com</span>
            </div>
            <div className='flex flex-row gap-2 text-lg font-semibold'>
              Phone Number: <span className='font-light'>+238 9111950</span>
            </div>
          </div>
        </section>
      ),
    },
  ];

  // State to track the currently active section
  const [activeSection, setActiveSection] = useState("");

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollPosition = window.scrollY + 300; // Add the offset here
    let newActiveSection = "";

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        const { offsetTop, offsetHeight } = sectionElement;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          newActiveSection = section.id;
        }
      }
    });

    if (
      newActiveSection !== activeSection &&
      newActiveSection !== null &&
      newActiveSection !== ""
    ) {
      setActiveSection(newActiveSection);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  useEffect(() => {
    if (!hashProcessed.current) {
      // Get the hash from the URL
      const hash = location.hash;
      if (hash) {
        // Remove the '#' character
        const sectionId = hash.substring(1);
        // Check if the section exists in your sections array
        if (sections.some((section) => section.id === sectionId)) {
          scrollToSection(sectionId);
          setActiveSection(sectionId);
        }
      }
      hashProcessed.current = true;
    }
  }, [location, sections]);

  // Function to scroll to a section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - 300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <div className=''>
      <div className='flex flex-col'>
        <div className='z-30'>
          <NavBar />
        </div>
        <motion.div
          className='h-full bg-zinc-50 mt-20 flex'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          exitBeforeEnter={true}
        >
          <div className='relative w-full'>
            <div className='flex bg-gray-100'>
              <div className='w-0 md:w-1/4'>
                <div className='fixed invisible md:visible p-16 py-20 left-0 top-0 h-full overflow-y-auto mt-20'>
                  <h1 className='text-3xl'>Table of Contents:</h1>
                  <ul className='pl-6'>
                    {sections.map((section) => (
                      <li key={section.id} className='my-5'>
                        <a
                          href={`#${section.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(section.id);
                          }}
                          className={
                            activeSection === section.id
                              ? "font-bold text-lg drop-shadow-lg"
                              : ""
                          }
                        >
                          {section.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-full md:w-3/5 p-6 md:p-20 pl-1/4 pt-36'>
                <h1 className='text-4xl font-bold'>Contact Us</h1>
                {sections.map((section) => (
                  <div
                    key={section.id}
                    id={section.id}
                    className='my-16 text-lg p-4'
                  >
                    <h2 className='text-2xl font-semibold'>{section.title}</h2>
                    <p>{section.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;

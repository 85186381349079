import { useEffect } from "react";

import NavBar from "../components/navigation/nav-bar";
import LoginForm from "../components/auth/login-form";

export default function Login() {
  useEffect(() => {
    document.title = "FAS Login";
  }, []);

  return (
    <div className='flex flex-col h-full w-auto'>
      <NavBar />
      <LoginForm />
    </div>
  );
}

import { motion } from "framer-motion";
import logo from "../../images/logo-no-bird.png";

const ContactForm = () => {
  return (
    <motion.div
      className='flex h-full flex-1 flex-col justify-center bg-gray-100 items-center pb-12'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Login Form Box */}
      <div className='p-10 mt-10 w-full min-w-sm rounded-2xl shadow-2xl border-2 border-zinc-900 bg-white'>
        {/* Company Log o*/}
        <div className='flex w-full mb-6'>
          <img
            className='mx-auto h-14 w-auto'
            src={logo}
            alt='Fly Away Students Logo'
          />
        </div>

        {/* Login Form */}
        <form className='space-y-6' action='/' method='POST'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-20'>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                First Name
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Last Name
              </label>
              <div className='mt-2'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
                />
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-gray-900'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='block w-full rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
              />
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-gray-900'
              >
                Message
              </label>
            </div>
            <div className='mt-2'>
              <textarea
                rows={6}
                maxLength='500'
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className='block w-full resize-none rounded-md border-0 p-1.5 px-3 text-gray-900 shadow-md ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-loose'
              />
            </div>
          </div>

          <div className='flex justify-center'>
            <button
              type='submit'
              className='flex w-1/2 justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-md hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default ContactForm;

import axios from 'axios';

const login = async (formData) => {
    try {
        const response = await axios.post('https://api-fly.flyawaystudents.com/api/auth/login', formData);
        return response.data;
    } catch (error) {
        throw new Error('Erro ao fazer login:', error);
    }
};

const logout = async (token) => {
    try {
        const response = await axios.post('https://api-fly.flyawaystudents.com/api/auth/logout', {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Erro ao fazer logout:', error);
    }
};

const logoutUser = async (token) => {
    try {
        const response = await axios.post('https://api-fly.flyawaystudents.com/api/applicants/logout', {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Erro ao fazer logout:', error);
    }
};

const fetchApplicantsData = async () => {
    try {
      const response = await axios.get('https://api-fly.flyawaystudents.com/api/applicants');
      return response.data; // Retorna os dados dos aplicantes
    } catch (error) {
      console.error('Erro ao buscar os aplicantes:', error);
      throw error; // Lança o erro para ser tratado onde a função for chamada
    }
  };


export { login, logout, fetchApplicantsData, logoutUser };
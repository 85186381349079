/* eslint-disable react/no-unescaped-entities */
import NavBar from "../components/navigation/nav-bar";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Footer from "../components/misc/footer";

const AboutUs = () => {
  const location = useLocation();
  const hashProcessed = useRef(false);
  // Content sections
  const sections = [
    {
      id: "step1",
      title: "Who We Are",
      content: (
        <section className='py-6 px-1'>
          <p className='my-5'>
            FAS has been a trusted partner for over 7 years, offering a
            comprehensive suite of services for students and professionals
            seeking to legally study English, immerse themselves in American
            culture, and pursue exciting career opportunities in the medical
            field.
          </p>
          <p className='my-5'>
            Are you a recent graduate seeking your first job? Let us help you
            make it an exciting and fulfilling experience. Whether you hold a
            bachelor's degree from a university in the USA or abroad, we
            specialize in facilitating opportunities for graduates in the
            medical field to work in the state of Massachusetts, with job
            openings in medical health.
          </p>
          <p className='my-5'>
            No matter your level of experience, we welcome applicants with a
            strong command of the English language, both written and spoken,
            along with a valid identity card or passport, and the ability to
            shift or relocate to the USA. Our clients sponsor visas, making your
            transition smoother and more accessible.
          </p>
          <p className='my-5'>
            Our services are entirely free for nurses and students aiming to
            become nurses and doctors. We are committed to providing assistance
            throughout the entire process, from education and language
            requirements to immigration and job placement, at no cost to you.
          </p>
          <p className='my-5'>
            Join us at FAS and embark on a rewarding journey in healthcare.
          </p>
        </section>
      ),
    },
  ];

  // State to track the currently active section
  const [activeSection, setActiveSection] = useState("");

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollPosition = window.scrollY + 300; // Add the offset here
    let newActiveSection = "";

    sections.forEach((section) => {
      const sectionElement = document.getElementById(section.id);
      if (sectionElement) {
        const { offsetTop, offsetHeight } = sectionElement;

        if (
          scrollPosition >= offsetTop &&
          scrollPosition < offsetTop + offsetHeight
        ) {
          newActiveSection = section.id;
        }
      }
    });

    if (
      newActiveSection !== activeSection &&
      newActiveSection !== null &&
      newActiveSection !== ""
    ) {
      setActiveSection(newActiveSection);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  useEffect(() => {
    if (!hashProcessed.current) {
      // Get the hash from the URL
      const hash = location.hash;
      if (hash) {
        // Remove the '#' character
        const sectionId = hash.substring(1);
        // Check if the section exists in your sections array
        if (sections.some((section) => section.id === sectionId)) {
          scrollToSection(sectionId);
          setActiveSection(sectionId);
        }
      }
      hashProcessed.current = true;
    }
  }, [location, sections]);

  // Function to scroll to a section
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop = element.offsetTop - 300;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  return (
    <div className=''>
      <div className='flex flex-col'>
        <div className='z-30'>
          <NavBar />
        </div>
        <motion.div
          className='h-full bg-gray-100 mt-20 flex'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          exitBeforeEnter={true}
        >
          <div className='relative w-full'>
            <div className='flex'>
              <div className='w-0 md:w-1/4'>
                <div className='invisible md:visible p-16 py-20 fixed left-0 top-0 h-full overflow-y-auto mt-20'>
                  <h1 className='text-3xl'>Table of Contents:</h1>
                  <ul className='pl-6'>
                    {sections.map((section) => (
                      <li key={section.id} className='my-5'>
                        <a
                          href={`#${section.id}`}
                          onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(section.id);
                          }}
                          className={
                            activeSection === section.id
                              ? "font-bold text-lg drop-shadow-lg"
                              : ""
                          }
                        >
                          {section.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='w-full md:w-3/5 p-20 pl-1/4 pt-36'>
                <h1 className='text-4xl font-bold'>About Us</h1>
                {sections.map((section) => (
                  <div
                    key={section.id}
                    id={section.id}
                    className='my-16 text-lg'
                  >
                    <h2 className='text-2xl font-semibold'>{section.title}</h2>
                    <p>{section.content}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </motion.div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;

import { useEffect } from "react";

import NavBar from "../components/navigation/nav-bar";
import LoginAdminForm from "../components/auth/login-admin";

export default function LoginAdmin() {
  useEffect(() => {
    document.title = "FAS Login";
  }, []);

  return (
    <div className='flex flex-col h-full w-auto'>
      <NavBar />
      <div className='h-20'>.</div>
      <LoginAdminForm />
    </div>
  );
}

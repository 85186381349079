const DegreeCell = ({ info }) => {
  if (info == null || info == "") {
    return (
      <div className='w-full flex justify-center'>
        <span>N/A</span>
      </div>
    );
  }
  return (
    <div className='w-full flex justify-center'>
      <span
        className={`rounded-full text-white text-sm font-semibold px-4 py-1 ${info == "PhD" ? "bg-green-500" : info == "MD" ? "bg-blue-500" : "bg-red-500"}`}
      >
        {info}
      </span>
    </div>
  );
};

export default DegreeCell;

import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";

const TableContainer = () => {
  return (
    <motion.div
      className='flex overflow-hidden h-full w-full p-6 bg-stone-200'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Outlet />
    </motion.div>
  );
};

export default TableContainer;
